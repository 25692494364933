import { Injectable } from '@angular/core';
import {  Subject } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class DataServiceService {
  openMywatch:boolean=false;
  private customSubject = new Subject<any>();
  customObservable = this.customSubject.asObservable();
  callComponentMethod(value:any) 
  {
    this.customSubject.next(value);
  }
  constructor() { }
}
