<div class="buying-block" style="height: 383px;">
    <div class="row">
        <input type="text" class="col-md-3 value-with-border" [(ngModel)]="inputValue" name="txtValue" />
        <div class="col col-md-2.5">
            <label class="value-input">
                <span class="lim-span">Min</span> 4250 (D)
            </label>
        </div>
        <div class="col col-md-2.5">
            <label class="value-input">
                <span class="lim-span">Max</span> 4250 (D)
            </label>
        </div>
        <igx-input-group class="col-md-4 symbol-input">
            <igx-suffix style="    margin-top: 5px;">
                <img src="/assets/dashboard/search-icon.svg" />
            </igx-suffix>
            <input igxInput name="txtSearch" type="search" />
            <label igxLabel for="txtSearch">Symbol</label>
        </igx-input-group>

    </div>

    <div class="row" style="margin-right: 0px;">
        <div class="col-md-4">
            <input type="text" class="value-label" style="width: 106%;" placeholder="Portfolio" name="txtPortfolio"
                onfocus="this.placeholder = ''" onblur="this.placeholder = 'Portfolio'" />
        </div>
        <div class="col-md-4">
            <input type="text" class="value-label" style="width: 106%;" placeholder="Quantity" name="txtQuantity"
                onfocus="this.placeholder = ''" onblur="this.placeholder = 'Quantity'" />
        </div>
        <div class="col-md-4">
            <input type="text" class="value-label" style="width: 106%;" placeholder="Price" name="txtPrice"
                onfocus="this.placeholder = ''" onblur="this.placeholder = 'Price'" />
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <input type="text" class="value-label" style="width: 103%;" placeholder="Order Type" name="txtOrderType"
                onfocus="this.placeholder = ''" onblur="this.placeholder = 'Order Type'" />
        </div>
        <div class="col-md-3">
            <igx-checkbox class="chk-label">
                <span class="chk-span">All or None</span>
            </igx-checkbox>
        </div>
        <div class="col-md-3">
            <igx-checkbox class="chk-label">
                <span class="chk-span">Day Order</span>
            </igx-checkbox>
        </div>
    </div>
    <div class="row" style="margin-right: 0px;">
        <div class="col-md-6">
            <input type="text" class="value-label" placeholder="Good Till" name="txtGoodTill"
                onfocus="this.placeholder = ''" onblur="this.placeholder = 'Good Till'" />
        </div>
        <div class="col-md-6">
            <input type="text" class="value-label" placeholder="Action" name="txtAction" onfocus="this.placeholder = ''"
                onblur="this.placeholder = 'Action'" />
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <igx-input-group class="order-amt">
                <igx-prefix class="span-amt" style="width:'100%'">Order Value</igx-prefix>
                <input igxInput name="orderValue" type="text" class="value-amt" [(ngModel)]="orderValue1" />
            </igx-input-group>
        </div>
        <div class="col-md-6">
            <igx-input-group class="order-amt">
                <igx-prefix class="span-amt" style="width:'100%'">Order Value</igx-prefix>
                <input igxInput name="orderValue" type="text" class="value-amt" [(ngModel)]="orderValue2" />
            </igx-input-group>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <igx-input-group class="order-amt">
                <igx-prefix class="span-amt" style="width:'100%'">Order Value</igx-prefix>
                <input igxInput name="orderValue" type="text" class="value-amt" [(ngModel)]="orderValue3" />
            </igx-input-group>
        </div>
        <div class="col-md-6">
            <igx-input-group class="order-amt">
                <igx-prefix class="span-amt" style="width:'100%'">Order Value</igx-prefix>
                <input igxInput name="orderValue" type="text" class="value-amt" [(ngModel)]="orderValue4" />
            </igx-input-group>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <igx-input-group class="order-amt">
                <igx-prefix class="span-amt" style="width:'100%'">Order Value</igx-prefix>
                <input igxInput name="orderValue" type="text" class="value-amt" [(ngModel)]="orderValue5" />
            </igx-input-group>
        </div>
        <div class="col-md-6">
            <igx-input-group class="order-amt">
                <igx-prefix class="span-amt" style="width:'100%'">Order Value</igx-prefix>
                <input igxInput name="orderValue" type="text" class="value-amt" [(ngModel)]="orderValue6" />
            </igx-input-group>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label igxLabel class="buy-label">
                Buying Power 100,000.00
            </label>
        </div>
        <div class="col-md-6">
            <span class="button-class">
                <button igxButton="raised" igxRipple [displayDensity]="density"
                    class="advanced-botton">Advanced</button>
                <button igxButton="raised" igxRipple [displayDensity]="density" class="buy-botton">Buy @ 20.5</button>
            </span>
        </div>
    </div>
</div>