<div class="watchlist-tab-wrap scrollbar" id="style-3">
    <div class="grid-toolbar-wrap">
        <igx-select [(ngModel)]="financialSelect" class="financial-select col-md-3">
            <igx-select-item value="Dubai" class="dropdown-item-geojit-dashboard">
                <span style="font-weight: bold;">DFM (D)</span><span style="font-weight: 500;">Dubai Financial
                    Market</span>
            </igx-select-item>
        </igx-select>
        <igx-select [(ngModel)]="sectorSelect" class="sector-select col-md-3">
            <igx-select-item value="All" class="dropdown-item-geojit-dashboard">All Sector</igx-select-item>
        </igx-select>
        <igx-select [(ngModel)]="marketSelect" class="market-select col-md-3">
            <igx-select-item value="Regular" class="dropdown-item-geojit-dashboard">Regular Market</igx-select-item>
        </igx-select>
        <igx-input-group class="search-input col-md-3">
            <igx-prefix>
                <img src="/assets/dashboard/search-icon.svg" style="padding: 10px 1px 1px 1px;;" />
                <igx-icon *ngIf="searchText.length > 0" (click)="clearSearch()">clear</igx-icon>
            </igx-prefix>
            <input placeholder="Search new" igxInput name="txtSearch" type="search" />
        </igx-input-group>
    </div>
</div>
<app-dynamic-grid class="watchlist-tab-wrap" [tableData]="watchlistData" [tableColumns]="watchlistColumns"
    [tableSort]="true" [tableFilter]="false" [tableAction]="'none'" [tableHeight]="'369'"></app-dynamic-grid>