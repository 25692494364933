import { Component, OnInit, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-grid',
  templateUrl: './dynamic-grid.component.html',
  styleUrls: ['./dynamic-grid.component.scss']
})
export class DynamicGridComponent implements OnInit 
{

  @Input() tableData!: any[];
  @Input() tableColumns!: any[];
  @Input() tableSort!: boolean;
  @Input() tableFilter!: boolean;
  @Input() tableAction!: string;
  @Input() tableHeight!: string;
  data!:any[];
	columns!:any[];
  sortData!:boolean;
  filterData!:boolean;
  action!:string;
  height!:string;
  fontWeight=500;
  constructor() { }
  ngOnInit(): void {
    this.columns = this.tableColumns;
    this.data = this.tableData;
    this.sortData = this.tableSort;
    this.filterData = this.tableFilter;
    this.action = this.tableAction;
    this.height = this.tableHeight;

  }

}
