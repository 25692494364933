import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  public title = "Samples";
  theme = "light-theme";
  sideBarOpen: boolean = false;
  dark: boolean = false;
  light: boolean = true;
  constructor(public renderer: Renderer2) {
  }

  ngOnInit(): void {
  }
  setTheme(value: string) {
    switch (value) {
      case "light":
        this.theme = "light-theme"
        this.light = true;
        this.dark = false;
        break;
      case "dark":
        this.theme = "dark-theme"
        this.dark = true;
        this.light = false;
        break;
      default:
        this.theme = "light-theme";
    }
  }
  toggleSideBar() {
    if (this.sideBarOpen) {
      this.sideBarOpen = false;
      document.getElementsByClassName('sidebar')[0].classList.remove('showsidebar');
    }
    else {
      document.getElementsByClassName('sidebar')[0].classList.add('showsidebar');
      this.sideBarOpen = true;
    }
  }

}
