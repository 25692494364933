import { Component, OnInit } from '@angular/core';
import { IgcDockManagerLayout,
  IgcDockManagerPaneType,
  IgcSplitPaneOrientation } from '@infragistics/igniteui-dockmanager';
@Component({
  selector: 'app-general-dashboard',
  templateUrl: './general-dashboard.component.html',
  styleUrls: ['./general-dashboard.component.scss']
})
export class GeneralDashboardComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }
  public title = "Samples";
  theme = "light-theme";
  public open: string='';

}
