<div class="row orderticket-box-geojit-ticket">
  <div class="row">
    <div class="col-75">
      <div class="container">
        <form action="/action_page.php">
          <div class="row">
            <div class="col-50">
              <h3 class="text-tdwl-geojit-ticket">1180 (D)<span style="font-size: 15px;font-weight: 500;">TDWL
                  (D)</span></h3>
              <label class="fname bank-name-geojit-ticke">The Saudi National Bank</label>
              <div class="buy-layout-geojit-ticket " style="margin-right: 3px;margin-bottom: 3px;background: #DFF8ED ;">
                <div class="row">
                  <div class="col-sm-2"></div>
                  <div class="col-sm-4">
                    <span class="row buy-geojit-ticket">Buy</span>
                    <span class="row buy-geojit-ticket" style="font-size: 13px;">0.232</span>
                  </div>
                  <div class="col-sm-6">
                    <span style="float: right;" class="label-btn-geojit-ticket">343323212</span>
                    <igx-linear-bar [value]="currentBuyValue" [max]="100"></igx-linear-bar>
                  </div>
                </div>
                <div class="row">
                  <button [ngClass]="statusClass" (click)="setActiveClass()"
                    class="order-ticket-buy-button">BUY</button>
                </div>
              </div>
              <div [ngClass]="bgActive" class="buy-layout-geojit-ticket" style="margin-right: 3px;height: 72px;">
                <div class="row">
                  <span class="col-md-12 quantity-text-geojit-ticket"
                    style="font-weight:lighter;margin-left: 2%;">Quantity</span>
                  <input class="col-md-12 value-with-border" placeholder="343323212" />
                </div>
              </div>
            </div>
            <div class="col-50" style="text-align: right;">
              <h3 style="margin-top: -6px;"><span class="text-green-geojit-ticket">+0.30%</span>
                <span class="text-black-geojit-ticket">54.00</span>
                <span class="text-greenbg-geojit-ticket">
                  <div style="display: contents;"><span class="material-icons arrow-upward">
                      arrow_upward
                    </span>
                    9.30</div>
                </span>
              </h3>
              <label class="fname min-label-geojit-ticket" style="font-weight: 500;margin-bottom: 6px;"><span
                  class="min-text-geojit-ticket">Min </span>48.60 <span class="min-text-geojit-ticket">Min
                </span>59.40</label>
              <div class="sell-layout-geojit-ticket" style="margin-bottom: 3px;">
                <div class="row">
                  <div class="col-sm-6">
                    <span style="float: left;" class="label-btn-geojit-ticket">343323212</span>
                    <igx-linear-bar [value]="currentBuyValue" [max]="100" type="warning"></igx-linear-bar>
                  </div>
                  <div class="col-sm-4" style="color: #19C17D;">
                    <span class="row buy-geojit-ticket">Buy</span>
                    <span class="row buy-geojit-ticket" style="font-size: 13px;">0.232</span>
                  </div>
                  <div class="col-sm-2"></div>
                </div>
                <div class="row">
                  <button [ngClass]="sellActive" (click)="sell()" class="order-ticket-sell-button">SELL</button>
                </div>
              </div>
              <div [ngClass]="bgActive" class="buy-layout-geojit-ticket" style="height: 72px;">
                <div style="float: left;">
                  <span class="col-md-12 quantity-text-geojit-ticket"
                    style="font-weight:lighter;text-align: left;">Price</span>
                  <input class="col-md-12 value-with-border" placeholder="343323212" />
                </div>
              </div>
            </div>
            <div class="col-50">
              <div [ngClass]="bgActive" class="col-sm-3 buy-layout-geojit-ticket"
                style="height: 72px;width: 170px;margin-right: 3px;margin-top: 3px;">
                <span class="col-md-12 quantity-text-geojit-ticket" style="font-weight:lighter;margin-left: 2%;">Order
                  Type</span>
                <igx-select [(ngModel)]="limitSelect" class="col-md-12 sector-select">
                  <label igxLabel>Limit</label>
                  <igx-select-item value="Limit">Limit</igx-select-item>
                </igx-select>
              </div>
              <div [ngClass]="bgActive" class="col-sm-9 buy-layout-geojit-ticket"
                style="height: 72px; width: 353px;margin-top: 3px;">
                <div style="width: 100%;    height: 50%;    margin-top: -5%;">
                  <igx-checkbox class="chk-label" style="width: 50%;--igx-checkbox-empty-color: #d0d2d1;">
                    <span class="chk-span">All or None</span>
                  </igx-checkbox>
                  <span class="quantity-text-geojit-ticket"
                    style="font-weight:lighter;margin-left: 2%; width: 50%;">Good Till</span>
                </div>
                <div style="width: 100%;    height: 50%;">
                  <igx-checkbox class="chk-label" style="width: 50%;--igx-checkbox-empty-color: #d0d2d1;">
                    <span class="chk-span">Day Order</span>
                  </igx-checkbox>
                  <igx-select [(ngModel)]="limitSelect" style="    width: 157px;
                                 margin-top: -11%;
                                 margin-left: 52%;
                                 height: 33px;" class="sector-select">
                    <label igxLabel>Limit</label>
                    <igx-select-item value="Limit">Limit</igx-select-item>
                  </igx-select>
                </div>
              </div>
            </div>
            <div class="col-md-12 ordertype-layout-geojit-ticket" style="padding: 8px;">
              <div class="row">
                <span class="col-md-3 quantity-text-geojit-ticket" style="font-weight:lighter;">Order Type</span>
                <span class="col-md-3 quantity-text-geojit-ticket"
                  style="font-weight:lighter;text-align: center;padding-left: 31px;">Currency</span>
                <span class="col-md-3 quantity-text-geojit-ticket" style="font-weight:lighter;text-align: right;">Buying
                  Power</span>
                <span class="col-md-3 quantity-text-geojit-ticket" style="font-weight:lighter;text-align: right;">Qty In
                  Hand</span>
              </div>
              <div class="row">
                <span class="col-md-3" style="font-weight:lighter;">
                  <igx-select [(ngModel)]="limitSelect" class="col-md-12 sector-select"
                    style="border: 1px solid #E2E6ED;border-radius: 3px;width: 153px;">
                    <label igxLabel>DFM, ADSM</label>
                    <igx-select-item value="Limit">Limit</igx-select-item>
                  </igx-select>
                </span>
                <span class="col-md-3" style="text-align: center;font-size: 13px;">
                  <label>Limit</label>
                </span>
                <span class="col-md-3"
                  style="font-weight:lighter;text-align: center;padding-left: 19px;font-size: 13px;">
                  <label>Limit</label>
                </span>
                <span class="col-md-3" style="font-weight:lighter;text-align: right;font-size: 13px;">
                  <label>Limit</label>
                </span>
              </div>
            </div><br>
            <div class="container col-md-12 advanced-layout-geojit-ticket">
              <label class="row" style="color: #2882DD;padding-left: 7px;">Advanced</label>
              <div class="row" style="padding-top: 11px;">
                <label class="col-md-3" style="font-weight: 500;">Place order if</label>
                <input placeholder="Limit" class="col-md-3 input-border-geojit-ticket">
                <input placeholder="Equal" class="col-md-3 input-border-geojit-ticket">
                <input class="col-md-3 input-border-geojit-ticket">
              </div>
              <div class="row">
                <label class="col-md-3" style="font-weight: 500;">Order Valid Until</label>
                <igx-date-picker [(ngModel)]="date" style="display: contents;">
                  <label>Choose a date</label>
                </igx-date-picker>
              </div>
              <div class="row" style="height: 10px;">
                <label class="col-sm-3" style="font-weight: 500;">Min Fill</label>
                <input placeholder="0" class="col-md-3 input-border-geojit-ticket">
                <label class="col-sm-3" style="font-weight: 500;">Disclosed</label>
                <input placeholder="0" class="col-md-3 input-border-geojit-ticket">
              </div>
            </div>
            <div class="col-50 ordervalue-layout-geojit-ticket">
              <label class="order-value-geojit-ticket">
                <span class="span-amt ordervalue-text-geojit-ticket">
                  Order Value
                </span>
                <span class="value-amt ordervalue-number-geojit-ticket">
                  30,00,000.00
                </span>
              </label>
            </div>
            <div class="col-50 ordervalue-layout-geojit-ticket">
              <label class="order-value-geojit-ticket">
                <span class="span-amt ordervalue-text-geojit-ticket">
                  Order Value
                </span>
                <span class="value-amt ordervalue-number-geojit-ticket">
                  -
                </span>
              </label>
            </div>
            <div class="col-50 ordervalue-layout-geojit-ticket">
              <label class="order-value-geojit-ticket">
                <span class="span-amt ordervalue-text-geojit-ticket">
                  Order Value
                </span>
                <span class="value-amt ordervalue-number-geojit-ticket">
                  -
                </span>
              </label>
            </div>
            <div class="col-50 ordervalue-layout-geojit-ticket">
              <label class="order-value-geojit-ticket">
                <span class="span-amt ordervalue-text-geojit-ticket">
                  Order Value
                </span>
                <span class="value-amt ordervalue-number-geojit-ticket">
                  30,00,000.00
                </span>
              </label>
            </div>
            <div class="col-50 ordervalue-layout-geojit-ticket">
              <label class="order-value-geojit-ticket">
                <span class="span-amt ordervalue-text-geojit-ticket">
                  Order Value
                </span>
                <span class="value-amt ordervalue-number-geojit-ticket">
                  -
                </span>
              </label>
            </div>
            <div class="col-50 ordervalue-layout-geojit-ticket">
              <label class="order-value-geojit-ticket">
                <span class="span-amt ordervalue-text-geojit-ticket">
                  Order Value
                </span>
                <span class="value-amt ordervalue-number-geojit-ticket">
                  -
                </span>
              </label>
            </div>
            <div class="row">
              <div class="col-50">
                <h3 class="text-buy-geojit-ticket">Buying Power 100,000.00</h3>
              </div>
              <div class="col-50" style="text-align: right;">
                <button igxButton="raised" igxRipple [displayDensity]="density" class="sell-button-geojit-ticket">Sell @
                  20.5</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>