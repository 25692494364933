<igc-dockmanager id="dockManagerw" #dockManagerw igx-typography  class="doc-manager-geojit-window" [layout]=windowLayout  style="--igc-tab-background-active: #E2E6ED;" >
    

<div slot="content1" class="dockManagerContent" style="background-color: #E2E6ED; padding: 8px;"  >
    <igc-dockmanager class="subdoc-manager-geojit-window" id="dockManager1" #dockManager1 [layout]=layoutscreen1   style="--igc-tab-background-active: #fff;height: 880px;--igc-background-color: #fff;border-radius:7px;">
        <div slot="content1" class="dockManagerContent" > 
            <app-watchlist-tabs></app-watchlist-tabs>                    
        </div> 
        <div slot="contentbuying" class="dockManagerContent "  style="background: white;"> <app-buying-layout></app-buying-layout></div>
        <div slot="content6" class="dockManagerContent"  style="background: white;    overflow: hidden;height: 307px;">
            <igc-dockmanager  [layout]=stocklayout style="--igc-background-color: #fff;border-radius:7px;height: 334px;
            overflow: hidden;
            max-height: 325px;">
             <div slot="time" class="dockManagerContent" style="background: white;overflow: auto;">
                 <app-stock-summary></app-stock-summary></div> </igc-dockmanager>      
        </div>
        <div slot="contentorder" class="dockManagerContent"  style="background: white;"><app-myorders-tabs></app-myorders-tabs></div>
        <div slot="content11" class="dockManagerContent" style="background: white"><app-tech-research-tabs></app-tech-research-tabs> </div>
        <div slot={{mywatch}} class="dockManagerContent" *ngFor="let mywatch of passid;" ><app-watchlist-tabs ></app-watchlist-tabs></div> 
        <div slot={{buyLayout}} class="dockManagerContent "   *ngFor="let buyLayout of buyid;"> <app-buying-layout></app-buying-layout></div>
        <div slot={{stockLayout}} class="dockManagerContent"   *ngFor="let stockLayout of stockid;"> <app-stock-summary ></app-stock-summary> </div>      
        <div slot={{orderLayout}} class="dockManagerContent"  *ngFor="let orderLayout of orderid;"><app-myorders-tabs></app-myorders-tabs></div>
        <div slot={{techLayout}} class="dockManagerContent" *ngFor="let techLayout of techid;"><app-tech-research-tabs></app-tech-research-tabs> </div>
        <div slot={{ticket}} class="dockManagerContent order-ticket-geojit-ticket" *ngFor="let ticket of ticketid;"><app-order-ticket></app-order-ticket> </div>
    </igc-dockmanager>  
</div>
<div slot="content2" class="dockManagerContent" style="background-color: #E2E6ED;"  >
        <igc-dockmanager class="subdoc-manager-geojit-window" id="dockManager2" #dockManager2 [layout]=layoutscreen2   style="--igc-tab-background-active: #fff;height: 880px;--igc-background-color: #fff;border-radius:7px;">
            <div slot="content2" class="dockManagerContent" > 
            </div>
            <div slot={{mywatch}} class="dockManagerContent" *ngFor="let mywatch of passid;" ><app-watchlist-tabs ></app-watchlist-tabs></div> 
            <div slot={{buyLayout}} class="dockManagerContent "   *ngFor="let buyLayout of buyid;"> <app-buying-layout></app-buying-layout></div>
            <div slot={{stockLayout}} class="dockManagerContent"   *ngFor="let stockLayout of stockid;"> <app-stock-summary ></app-stock-summary> </div>      
            <div slot={{orderLayout}} class="dockManagerContent"  *ngFor="let orderLayout of orderid;"><app-myorders-tabs></app-myorders-tabs></div>
            <div slot={{techLayout}} class="dockManagerContent" *ngFor="let techLayout of techid;"><app-tech-research-tabs></app-tech-research-tabs> </div>
            <div slot={{ticket}} class="dockManagerContent order-ticket-geojit-ticket" *ngFor="let ticket of ticketid;"><app-order-ticket></app-order-ticket> </div>
        </igc-dockmanager>
</div>
<div slot="content3" class="dockManagerContent" style="background-color: #E2E6ED;"  >
        <igc-dockmanager class="subdoc-manager-geojit-window" id="dockManager3" #dockManager3 [layout]=layoutscreen3   style="--igc-tab-background-active: #fff;height: 880px;--igc-background-color: #fff;border-radius:7px;">
            <div slot="content3" class="dockManagerContent" > 
            </div>
            <div slot={{mywatch}} class="dockManagerContent" *ngFor="let mywatch of passid;" ><app-watchlist-tabs ></app-watchlist-tabs></div> 
            <div slot={{buyLayout}} class="dockManagerContent "   *ngFor="let buyLayout of buyid;"> <app-buying-layout></app-buying-layout></div>
            <div slot={{stockLayout}} class="dockManagerContent"   *ngFor="let stockLayout of stockid;"> <app-stock-summary ></app-stock-summary> </div>      
            <div slot={{orderLayout}} class="dockManagerContent"  *ngFor="let orderLayout of orderid;"><app-myorders-tabs></app-myorders-tabs></div>
            <div slot={{techLayout}} class="dockManagerContent" *ngFor="let techLayout of techid;"><app-tech-research-tabs></app-tech-research-tabs> </div>
            <div slot={{ticket}} class="dockManagerContent order-ticket-geojit-ticket" *ngFor="let ticket of ticketid;"><app-order-ticket></app-order-ticket> </div>

        </igc-dockmanager>
</div>
<div slot="content4" class="dockManagerContent" style="background-color: #E2E6ED;"  >
        <igc-dockmanager class="subdoc-manager-geojit-window" id="dockManager4" #dockManager4 [layout]=layoutscreen4   style="--igc-tab-background-active: #fff;height: 880px;--igc-background-color: #fff;border-radius:7px;">
            <div slot="content4" class="dockManagerContent" > 
            </div>
            <div slot={{mywatch}} class="dockManagerContent" *ngFor="let mywatch of passid;" ><app-watchlist-tabs ></app-watchlist-tabs></div> 
            <div slot={{buyLayout}} class="dockManagerContent "   *ngFor="let buyLayout of buyid;"> <app-buying-layout></app-buying-layout></div>
            <div slot={{stockLayout}} class="dockManagerContent"   *ngFor="let stockLayout of stockid;"> <app-stock-summary ></app-stock-summary> </div>      
            <div slot={{orderLayout}} class="dockManagerContent"  *ngFor="let orderLayout of orderid;"><app-myorders-tabs></app-myorders-tabs></div>
            <div slot={{techLayout}} class="dockManagerContent" *ngFor="let techLayout of techid;"><app-tech-research-tabs></app-tech-research-tabs> </div>
            <div slot={{ticket}} class="dockManagerContent order-ticket-geojit-ticket" *ngFor="let ticket of ticketid;"><app-order-ticket></app-order-ticket> </div>

        </igc-dockmanager>
</div>
<div slot="content5" class="dockManagerContent" style="background-color: #E2E6ED;"  >
        <igc-dockmanager class="subdoc-manager-geojit-window" id="dockManager5" #dockManager5 [layout]=layoutscreen5   style="--igc-tab-background-active: #fff;height: 880px;--igc-background-color: #fff;border-radius:7px;">
            <div slot="content5" class="dockManagerContent" > 
            </div>
            <div slot={{mywatch}} class="dockManagerContent" *ngFor="let mywatch of passid;" ><app-watchlist-tabs ></app-watchlist-tabs></div> 
            <div slot={{buyLayout}} class="dockManagerContent "   *ngFor="let buyLayout of buyid;"> <app-buying-layout></app-buying-layout></div>
            <div slot={{stockLayout}} class="dockManagerContent"   *ngFor="let stockLayout of stockid;"> <app-stock-summary ></app-stock-summary> </div>      
            <div slot={{orderLayout}} class="dockManagerContent"  *ngFor="let orderLayout of orderid;"><app-myorders-tabs></app-myorders-tabs></div>
            <div slot={{techLayout}} class="dockManagerContent" *ngFor="let techLayout of techid;"><app-tech-research-tabs></app-tech-research-tabs> </div>
            <div slot={{ticket}} class="dockManagerContent order-ticket-geojit-ticket" *ngFor="let ticket of ticketid;"><app-order-ticket></app-order-ticket> </div>

        </igc-dockmanager>
</div>
<div slot="content6" class="dockManagerContent" style="background-color: #E2E6ED;"  >
        <igc-dockmanager class="subdoc-manager-geojit-window" id="dockManager6" #dockManager6 [layout]=layoutscreen6   style="--igc-tab-background-active: #fff;height: 880px;--igc-background-color: #fff;border-radius:7px;">
            <div slot="content6" class="dockManagerContent" > 
            </div>
            <div slot={{mywatch}} class="dockManagerContent" *ngFor="let mywatch of passid;" ><app-watchlist-tabs ></app-watchlist-tabs></div> 
            <div slot={{buyLayout}} class="dockManagerContent "   *ngFor="let buyLayout of buyid;"> <app-buying-layout></app-buying-layout></div>
            <div slot={{stockLayout}} class="dockManagerContent"   *ngFor="let stockLayout of stockid;"> <app-stock-summary ></app-stock-summary> </div>      
            <div slot={{orderLayout}} class="dockManagerContent"  *ngFor="let orderLayout of orderid;"><app-myorders-tabs></app-myorders-tabs></div>
            <div slot={{techLayout}} class="dockManagerContent" *ngFor="let techLayout of techid;"><app-tech-research-tabs></app-tech-research-tabs> </div>
            <div slot={{ticket}} class="dockManagerContent order-ticket-geojit-ticket" *ngFor="let ticket of ticketid;"><app-order-ticket></app-order-ticket> </div>

        </igc-dockmanager>
</div>
<div slot="content7" class="dockManagerContent" style="background-color: #E2E6ED;"  >
        <igc-dockmanager class="subdoc-manager-geojit-window" id="dockManager7" #dockManager7 [layout]=layoutscreen7   style="--igc-tab-background-active: #fff;height: 880px;--igc-background-color: #fff;border-radius:7px;">
            <div slot="content7" class="dockManagerContent" > 
            </div>
            <div slot={{mywatch}} class="dockManagerContent" *ngFor="let mywatch of passid;" ><app-watchlist-tabs ></app-watchlist-tabs></div> 
            <div slot={{buyLayout}} class="dockManagerContent "   *ngFor="let buyLayout of buyid;"> <app-buying-layout></app-buying-layout></div>
            <div slot={{stockLayout}} class="dockManagerContent"   *ngFor="let stockLayout of stockid;"> <app-stock-summary ></app-stock-summary> </div>      
            <div slot={{orderLayout}} class="dockManagerContent"  *ngFor="let orderLayout of orderid;"><app-myorders-tabs></app-myorders-tabs></div>
            <div slot={{techLayout}} class="dockManagerContent" *ngFor="let techLayout of techid;"><app-tech-research-tabs></app-tech-research-tabs> </div>
            <div slot={{ticket}} class="dockManagerContent order-ticket-geojit-ticket" *ngFor="let ticket of ticketid;"><app-order-ticket></app-order-ticket> </div>

        </igc-dockmanager>
</div>
<div slot="content8" class="dockManagerContent" style="background-color: #E2E6ED;"  >
        <igc-dockmanager class="subdoc-manager-geojit-window" id="dockManager8" #dockManager8 [layout]=layoutscreen8   style="--igc-tab-background-active: #fff;height: 880px;--igc-background-color: #fff;border-radius:7px;">
            <div slot="content8" class="dockManagerContent" > 
            </div>
            <div slot={{mywatch}} class="dockManagerContent" *ngFor="let mywatch of passid;" ><app-watchlist-tabs ></app-watchlist-tabs></div> 
            <div slot={{buyLayout}} class="dockManagerContent "   *ngFor="let buyLayout of buyid;"> <app-buying-layout></app-buying-layout></div>
            <div slot={{stockLayout}} class="dockManagerContent"   *ngFor="let stockLayout of stockid;"> <app-stock-summary ></app-stock-summary> </div>      
            <div slot={{orderLayout}} class="dockManagerContent"  *ngFor="let orderLayout of orderid;"><app-myorders-tabs></app-myorders-tabs></div>
            <div slot={{techLayout}} class="dockManagerContent" *ngFor="let techLayout of techid;"><app-tech-research-tabs></app-tech-research-tabs> </div>
            <div slot={{ticket}} class="dockManagerContent order-ticket-geojit-ticket" *ngFor="let ticket of ticketid;"><app-order-ticket></app-order-ticket> </div>

        </igc-dockmanager>
</div>
<div slot="content9" class="dockManagerContent" style="background-color: #E2E6ED;"  >
        <igc-dockmanager class="subdoc-manager-geojit-window" id="dockManager9" #dockManager9 [layout]=layoutscreen9   style="--igc-tab-background-active: #fff;height: 880px;--igc-background-color: #fff;border-radius:7px;">
            <div slot="content9" class="dockManagerContent" > 
            </div>
            <div  slot={{mywatch}} class="dockManagerContent" *ngFor="let mywatch of passid;" ><app-watchlist-tabs ></app-watchlist-tabs></div> 
            <div slot={{buyLayout}} class="dockManagerContent "   *ngFor="let buyLayout of buyid;"> <app-buying-layout></app-buying-layout></div>
            <div slot={{stockLayout}} class="dockManagerContent"   *ngFor="let stockLayout of stockid;"> <app-stock-summary ></app-stock-summary> </div>      
            <div slot={{orderLayout}} class="dockManagerContent"  *ngFor="let orderLayout of orderid;"><app-myorders-tabs></app-myorders-tabs></div>
            <div slot={{techLayout}} class="dockManagerContent" *ngFor="let techLayout of techid;"><app-tech-research-tabs></app-tech-research-tabs> </div>
            <div slot={{ticket}} class="dockManagerContent order-ticket-geojit-ticket" *ngFor="let ticket of ticketid;"><app-order-ticket></app-order-ticket> </div>

        </igc-dockmanager>
</div>
</igc-dockmanager>

