import { Component, OnInit ,ViewChild,Output,EventEmitter} from '@angular/core';
import { WindowTabsComponent } from '../../general-dashboard/window-tabs/window-tabs.component';
import { DataServiceService } from '../../services/data-service.service';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
  providers :[{ provide: WindowTabsComponent, useClass: WindowTabsComponent }]
})
export class DashboardHeaderComponent implements OnInit {
  public open: string='';
  constructor(public DataServiceService:DataServiceService) { }

  ngOnInit(): void {
    this.open='open';
  }
  startWindow(value:any)
  {
   this.DataServiceService.callComponentMethod(value);
  }
}
