export const watchlistData = [
  {

    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
}, 
{
  Symbol: 'GOOGL (D)',
  Last:587.69,
  Chg_per:-1.79,
  Chg:-2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
},
  {

    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
  },
  {

    Symbol: 'GOOGL (D)',
  Last:568.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
},
  {

    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:-1.79,
  Chg:-2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
},
{
  Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
}, 
{

  Symbol: 'GOOGL (D)',
  Last:574.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
},
 {
  Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:-1.79,
  Chg:-2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
 },
 {
     Symbol: 'GOOGL (D)',
  Last:551.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
 },
  {

    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
  },
  {

    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567},
  {

    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
  },
  {

    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
  },
  {

    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567}, 
  {

    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
  },
   {
  
    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
   },
   {
  
    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
},
  {

    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
},
{

  Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567 
 },
 { 
  Symbol: 'GOOGL (D)',
    Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
 },
 {
  Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
 },
  {

    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567

}, {
  
  Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
 },
  {

    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
  },
  {

    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
  },
   {
  
    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
},
 {
  Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
 },
  {

    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
  },
   {
  
    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
    },
     {
      Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
     },
     {
      Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567},
   {
  
    Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:  1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567
}, {
  
  Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567 
  
},
{

  Symbol: 'GOOGL (D)',
  Last:571.69,
  Chg_per:1.79,
  Chg:2.20,
  Last_bg:'green',
  DayRange:'25.72%',
  Bid:2.20,
  Offer:1.79,
  Volume:451761,
  Trades:451761,
  WkRange:'25.72%',
  Open:601.55,
  SDescription:'GOOGL',
  Currency:'AED',
  LastQty:329345567


  }
  ];