<header class="header">
    <nav class="navbar navbar-expand-sm fixed-top py-3 header-geojit-home">
        <div class="container">
            <img class="logo" src="assets/home/logo.svg">           
            <div id="navbarSupportedContent" class="collapse navbar-collapse header-right-geojit-landing ">  
            </div>
            <select class="selectpicker language-select-geojit-home"  data-width="fit">
                <option data-content='<span class="flag-icon flag-icon-us"></span> English'>English</option>
              <option  data-content='<span class="flag-icon flag-icon-mx"></span> Español'>Español</option>
            </select>
        </div>
    </nav>
</header>