<div class="container tech-container-geojit-quod scrollbar" id="style-3">
    <div class="row">
        <div class="col-md-10">
            <p class="msn-geojit-quod">MSN . 3d ago</p>
            <p class="msn-content-geojit-quod">It is a long established fact that a reader will be distracted by the
                readable content of a page when looking layout.</p>
            <p class="msn-footer-geojit-quod">The point of using Lorem Ipsum is more-or-less normal distribution…</p>
        </div>
        <div class="col-md-2 msn-img-geojit-quod">
            <img src="assets/dashboard/Rectangle.png" />
        </div>
    </div>
    <div class="row">
        <div class="col-md-10">
            <p class="msn-geojit-quod">MSN . 3d ago</p>
            <p class="msn-content-geojit-quod">It is a long established fact that a reader will be distracted by the
                readable content of a page when looking layout.</p>
            <p class="msn-footer-geojit-quod">The point of using Lorem Ipsum is more-or-less normal distribution…</p>
        </div>
        <div class="col-md-2 msn-img-geojit-quod">
            <img src="assets/dashboard/Rectangle2.png" />
        </div>
    </div>
    <div class="row">
        <div class="col-md-10">
            <p class="msn-geojit-quod">MSN . 3d ago</p>
            <p class="msn-content-geojit-quod">It is a long established fact that a reader will be distracted by the
                readable content of a page when looking layout.</p>
            <p class="msn-footer-geojit-quod">The point of using Lorem Ipsum is more-or-less normal distribution…</p>
        </div>
        <div class="col-md-2 msn-img-geojit-quod">
            <img src="assets/dashboard/Rectangle3.png" />
        </div>
    </div>
    <div class="row">
        <div class="col-md-10">
            <p class="msn-geojit-quod">MSN . 3d ago</p>
            <p class="msn-content-geojit-quod">It is a long established fact that a reader will be distracted by the
                readable content of a page when looking layout.</p>
            <p class="msn-footer-geojit-quod">The point of using Lorem Ipsum is a more-or-less normal distribution…</p>
        </div>
        <div class="col-md-2 msn-img-geojit-quod">
            <img src="assets/dashboard/Rectangle4.png" />
        </div>
    </div>
</div>