import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stock-summary',
  templateUrl: './stock-summary.component.html',
  styleUrls: ['./stock-summary.component.scss']
})
export class StockSummaryComponent implements OnInit {

  public data: any;
  stock_summary_options: any;
  passid: any;
  width: any;
  margin: any;

  constructor(private router: Router) {
    if (window.screen.width <= 1500) {
      this.width = 400;
      this.margin = 280;
    }
    else if (window.screen.width >= 1501) {
      this.width = 480;
      this.margin = 280;
    }
  }
  openChart() {
    var thisnew = this;
    function makeRandom(lengthOfCode: number, possible: string) {
      let text = "";
      for (let i = 0; i < lengthOfCode; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      thisnew.passid = text;
      return text;
    }
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lengthOfCode = 10;
    makeRandom(lengthOfCode, possible);
    this.chart(thisnew.passid);
  }

  chart(id: any) {

    this.passid = id;


    setTimeout(() => {
      Highcharts.chart((this.passid).toString(), {
        chart: {
          width: this.width,
          height: this.margin,
          marginRight: 40,
        },

        legend: {
          symbolWidth: 0,
        },
        title: {
          text: '',
        },
        xAxis: {
          categories: ['800', '700', '600', '500', '400', '300', '200', '100'],
          gridLineWidth: 1,
          scrollbar: {
            enabled: true
          },
          labels: {
            style: {
              color: '#ABB3C3'
            }
          },
          gridLineDashStyle: 'Dash'
        },
        yAxis: [{
          gridLineWidth: 1,
          min: 0,
          scrollbar: {
            enabled: true
          },
          title: {
            text: ''
          },
          opposite: true,
          labels: {
            style: {
              color: '#ABB3C3'
            }
          },
          gridLineDashStyle: 'Dash',
          tickInterval: 80

        }],
        plotOptions: {
          areaspline: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, '#F6F9FF'],
                [1, '#F8FBFF00']
              ]
            },
            lineWidth: 1,
            marker: {
              enabled: false
            },
            showInLegend: false,
            stacking: 'normal'
          }
        },
        series: [{

          showInLegend: true,
          type: 'areaspline',
          lineWidth: 2,
          data: [400, 300, 200, 100, 200, 400, 100],
          name: '',
          color: '#2882DD',
        },
        ],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 200
            },
            chartOptions: {
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal'
              }
            }
          }]
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },

      });
    }, 1)
  }

  ngOnInit(): void {
    this.openChart();


  }

}
