import { Component, OnInit } from '@angular/core';
import { watchlistData } from './data';

@Component({
  selector: 'app-watchlist-tabs',
  templateUrl: './watchlist-tabs.component.html',
  styleUrls: ['./watchlist-tabs.component.scss']
})
export class WatchlistTabsComponent implements OnInit {

  public watchlistData: any[];
  watchlistColumns:any[];

  financialSelect:string='';
  sectorSelect:string='';
  marketSelect:string='';
  searchText:string='';
  
  constructor() { 
    this.watchlistData = watchlistData;
    this.watchlistColumns =[
      { field: "Symbol", header: "Symbol",icon:false, width: "100px", type: "string", image:false, action:false,color:false,background:false,fontWeight:600,sortData:false},
      { field: "", header: "",icon:false, width: "45px", type: "string", image:true, action:false,color:false,background:false,fontWeight:600,sortData:false},
			{ field: "Last", header: "Last",icon:true, width: "80", type: "number", image:false, action:false,color:false,background:true,fontWeight:600,sortData:true},
			{ field: "Chg_per", header: "%Chg.",icon:true, width: "75", type: "number", image:false , action:false,color:true,background:false,fontWeight:600,sortData:true},
			{ field: "Chg", header: "Chg.",icon:true, width: "75", type: "number", image:false, action:false,color:true,background:false,fontWeight:600,sortData:true},
			{ field: "DayRange", header: "Day Range",icon:true, width: "60", type: "string" , image:false, action:false,color:false,background:false,fontWeight:600,sortData:false},
			{ field: "Bid", header: "Bid",icon:true, width: "70", type: "number" , image:false, action:false,color:false,background:false,fontWeight:600,sortData:true},
			{ field: "Offer", header: "Offer",icon:true, width: "70", type: "number", image:false , action:false,color:false,background:false,fontWeight:600,sortData:true},
			{ field: "Volume", header: "Volume",icon:true, width: "90", type: "number", image:false , action:false,color:false,background:false,fontWeight:600,sortData:true},
			{ field: "Trades", header: "Trades",icon:true, width: "90", type: "number", image:false , action:false,color:false,background:false,fontWeight:600,sortData:true},
			{ field: "WkRange", header: "52Wk Range",icon:true, width: "65", type: "string", image:false , action:false,color:false,background:false,fontWeight:600,sortData:false},
			{ field: "Open", header: "Open", width: "85",icon:true, type: "number", image:false , action:false,color:false,background:false,fontWeight:600,sortData:true},
			{ field: "SDescription", header: "S. Description",icon:false, width: "90", type: "string", image:false, action:false,color:false,background:false,fontWeight:600,sortData:false },
			{ field: "Currency", header: "Cur.",icon:false, width: "50", type: "string", image:false, action:false ,color:false,background:false,fontWeight:600,sortData:false},
			{ field: "LastQty", header: "Last Qty",icon:true, width: "110", type: "number", image:false , action:false,color:false,background:false,fontWeight:600,sortData:true},
			{ field: "", header: "Trend",icon:false, width: "40", type: "string", image:false, action:true,color:false,background:false,fontWeight:600,sortData:false}
    ];

  }

  ngOnInit(): void {
    this.financialSelect='Dubai';
    this.sectorSelect="All";
    this.marketSelect="Regular";
  }
  private downFontCondition = (rowData: any, columnKey: any): boolean => {
    return rowData['Last_bg'] =='red';
  };
  private upFontCondition = (rowData: any, columnKey: any): boolean => {
    return rowData['Last_bg'] =='green';
  };
  public lastBgClasses = {
    downFont: this.downFontCondition,
    upFont: this.upFontCondition
  };
  clearSearch()
  {
  }
}
