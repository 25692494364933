import { Component, OnInit } from '@angular/core';
import { myOrdersData } from './data';

@Component({
  selector: 'app-myorders-tabs',
  templateUrl: './myorders-tabs.component.html',
  styleUrls: ['./myorders-tabs.component.scss']
})
export class MyordersTabsComponent implements OnInit {

  public myOrdersData: any[];
  myOrdersColumns:any[];

  constructor() {
    this.myOrdersData = myOrdersData;
    this.myOrdersColumns =[
      { field: "column1", header: "", width: "94", type: "string", image:false, action:false,color:false,background:false},
      { field: "column2", header: "", width: "117", type: "string", image:false, action:false,color:false,background:false},
			{ field: "column3", header: "", width: "110", type: "number", image:false, action:false,color:false,background:false},
			{ field: "column4", header: "", width: "110", type: "number", image:false , action:false,color:false,background:false},
    ];
   }

  ngOnInit(): void {
  }

}
