<header class="header-user-geojit-dashboard">
    <nav class="navbar navbar-expand-lg " style="height: 32px;">
        <div class="container" style="max-width: 100%;    padding-bottom: 19px;">
            <img class="user-icon-geojit-header" src="assets/dashboard/user-icon.svg">
            <span class="user-name-geojit-landing">QFT123_456</span> 
            <div id="navbarSupportedContent" class="collapse navbar-collapse header-right-geojit-landing "></div>
            <igx-select class="select-view-geojit-landing padding-view-geojit-header"  >
                <label igxLabel class="igx-label-select-header dropdown-item-geojit-dashboard">View</label>
                <igx-select-item value="View1" class=" dropdown-item-geojit-dashboard">View</igx-select-item>
                <igx-select-item value="View2" class=" dropdown-item-geojit-dashboard">View</igx-select-item>
                <igx-select-item value="View3" class=" dropdown-item-geojit-dashboard">View</igx-select-item>
                <igx-select-item value="View4" class=" dropdown-item-geojit-dashboard">View</igx-select-item>
            </igx-select>
            <igx-select class="select-view-geojit-landing padding-account-geojit-header" >
                <label igxLabel class="igx-label-select-header">Account</label>
                <igx-select-item value="Account1" class=" dropdown-item-geojit-dashboard">Account</igx-select-item>
                <igx-select-item value="Account2" class=" dropdown-item-geojit-dashboard">Account</igx-select-item>
                <igx-select-item value="Account3" class=" dropdown-item-geojit-dashboard">Account</igx-select-item>
                <igx-select-item value="Account4" class=" dropdown-item-geojit-dashboard">Account</igx-select-item>
            </igx-select>
            <igx-select class="select-view-geojit-landing padding-research-geojit-header" >
                <label igxLabel class="igx-label-select-header">Research</label>
                <igx-select-item value="Research1 " class=" dropdown-item-geojit-dashboard">Research</igx-select-item>
                <igx-select-item value="Research2" class=" dropdown-item-geojit-dashboard">Research</igx-select-item>
                <igx-select-item value="Research3" class=" dropdown-item-geojit-dashboard">Research</igx-select-item>
                <igx-select-item value="Research4" class=" dropdown-item-geojit-dashboard">Research</igx-select-item>
            </igx-select>
            <igx-select class="select-view-geojit-landing padding-tool-geojit-header" >
                <label igxLabel class="igx-label-select-header">Tools</label>
                <igx-select-item value="Tools1" class=" dropdown-item-geojit-dashboard">Tools</igx-select-item>
                <igx-select-item value="Tools2" class=" dropdown-item-geojit-dashboard">Tools</igx-select-item>
                <igx-select-item value="Tools3" class=" dropdown-item-geojit-dashboard">Tools</igx-select-item>
                <igx-select-item value="Tools4" class=" dropdown-item-geojit-dashboard">Tools</igx-select-item>
            </igx-select>
            <span class="language-text-geojit-dashboard">English | USD</span>
            <button class="button-icons-geojit-header"><img class="settings-image-geojit-dashboard" src="assets/dashboard/settings.svg"></button>
            <button class="button-icons-geojit-header"><img  class="settings-image-geojit-dashboard" src="assets/dashboard/dropdown.svg"></button>    
        </div>
    </nav>
</header>
<div class="header-user-geojit-dashboard height-subfooter-geojit-header" >
    <nav class="navbar navbar-expand-lg sub-navbar-geojit-header" style="height: 56px;">
        <div class="container" style="max-width: 100%;   ">
            <img src="/assets/dashboard/Group.svg" class="sub-img"/>
           <div class="section-rate-geojit-dashboard">
               <div class="row">
                    <div class="col-sm-3">
                        <img src="/assets/dashboard/flag.svg" style="padding-left: 13px;">
                        <label class="label-twdl-geojit-dashboard" style="padding-left: 7px;">TDWL (D)</label>
                    </div>
                    <div class="col-sm-3 open-section-geojit-header" >
                        <igx-radio [(ngModel)]="open"  value="open" class="radio-open-geojit-dashboard">
                            <span class="span-open-geojit-dashboard">Open</span>
                        </igx-radio>
                        <label igxLabel class="label-time-geojit-dashboard">10:06:24</label>
                        <button class="button-icons-geojit-header"><img src="/assets/dashboard/blue-dropdown.svg" class="image-button-geojit-header" ></button> 
                    </div>
                  
                    <div class="col-sm-3 ">
                        <label class="label-twdlamount-geojit-dashboard" >TDWL (D)</label>
                        <label class="label-amount-geojit-dashboard" >10,256.39</label>
                    </div>
                    <div class="col-sm-3">
                        <span style="float: right;">
                            <label class="label-twdl-geojit-dashboard" style="font-weight: 400;margin-right: 5px;">7.85</label>
                            <img src="/assets/dashboard/arrow-top.svg" >
                            <label class="label-twdl-geojit-dashboard" style="color: #19C17D;margin-right: 5px; ">0.08%</label>
                            <button class="button-icons-geojit-header" style="padding: 1px;"> <img src="/assets/dashboard/blue-dropdown.svg" class="dropdownblue-right-geojit-header"></button>
                        </span>
                   </div>
               </div>
           </div>
            <div id="navbarSupportedContent" class="collapse navbar-collapse  ">   
            </div>
            <button (click)="startWindow('startWorkspace')" class=" add-workspace-geojit-header"> +
            </button>
            <button (click)="startWindow('startWatch')" class="button-icons-geojit-dashboards">
                <img  src="/assets/dashboard/note.svg"/>
             </button>
             <button (click)="startWindow('startStock')" class="button-icons-geojit-dashboards">
                 <img  src="/assets/dashboard/bell.svg"/>
              </button>
              <button (click)="startWindow('startOrder')" class="button-icons-geojit-dashboards">
                 <img src="/assets/dashboard/pie.svg"/>
              </button>
              <button (click)="startWindow('startBuy')" class="button-icons-geojit-dashboards">
                <img src="/assets/dashboard/note.svg"/>
             </button>
             <button  (click)="startWindow('startTech')" class="button-icons-geojit-dashboards">
                 <img src="/assets/dashboard/bell.svg"/>
              </button>
              <button (click)="startWindow('orderTicket')" class="button-icons-geojit-dashboards">
                 <img src="/assets/dashboard/pie.svg"/>
              </button>
              <button  class="button-icons-geojit-dashboards">
                <img src="/assets/dashboard/note.svg"/>
             </button>
             <button class="button-icons-geojit-dashboards">
                 <img src="/assets/dashboard/bell.svg"/>
              </button>
              <button class="button-icons-geojit-dashboards">
                 <img src="/assets/dashboard/pie.svg"/>
              </button>
           <button  class="button-icons-geojit-dashboards" style="margin-right: 0px;">
                <img src="/assets/dashboard/note.svg"/>
             </button>
        </div>
    </nav>
</div>