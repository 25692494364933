<div class="section-banner-geojit-landing">
    <header class="header">
        <nav class="navbar navbar-expand-lg fixed-top py-3 header-geojit-landing">
            <div class="container">
                <img class="logo" src="assets/landing/logo.svg">
                <div id="navbarSupportedContent" class=" navbar-collapse header-right-geojit-landing ">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item"><a href="#" class="nav-link  font-weight-bold">About us</a></li>
                        <li class="nav-item"><a href="#" class="nav-link  font-weight-bold">Products</a></li>
                        <li class="nav-item"><a href="#" class="nav-link  font-weight-bold">Open new account</a></li>
                    </ul>
                </div>
                <button class="btn-geojit-landing btn-mobile-geojit-landing" [routerLink]="['/home/signin']">Sign
                    in</button>
                <button class="btn-trydemp-geojit-landing btn-mobile-geojit-landing">Try Demo</button>
                <button type="button " (click)="toggleSideBar()" class="btn display-onmobile-geojit-landing"
                    style="color: #61c5b1;">
                    <img class="logo" src="assets/landing/menu.png" style="    width: 33px;
                height: 33px;">
                </button>
            </div>
        </nav>
    </header>
    <div class="sidebar">
        <ul style="padding-left: 46px;">
            <li>About us</li>
            <li style="padding-top: 20px;">Products</li>
            <li style="padding-top: 20px;">Open new account</li>
            <button class="btn-trydemp-geojit-landing" style="margin-top: 20px;">Try Demo</button>
            <button class="btn-geojit-landing " style="margin-top: 20px;" [routerLink]="['/home/signin']">Sign
                in</button>
        </ul>
    </div>
    <section class="banner-bottom section-getstarted-geojit-landing">
        <div class="d-flex">
            <div class="container py-md-4  ">
                <div class="row text-left movegrid-down-geojit-landing">
                    <div class="col-md-6 ab-contents mobile-padding-geojit-landing">
                        <div class="col">
                            <h4 igx-typography__h4>Secure Investing for<br> Everyday Traders</h4>
                        </div>
                        <div class="col">
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing <br>diam nonumy eirmod tempor invidunt.
                            </p>
                        </div>
                        <div class="col get-started-geojit-landing">
                            <button igxButton="raised" igxRipple>Get Started</button>
                            <button style="background: transparent;width: auto;"><img src="assets/landing/play.svg"
                                    class="btn-play-geojit-landing" alt="" />Watch Now</button>
                        </div>
                    </div>
                    <div class="col-md-6 content-gd-wthree d-flex align-items-center" style="justify-content: center;">
                        <div>
                            <img src="assets/landing/lap.png" class=" mainscreen-image-geojit-landing " alt="" />
                            <img src="assets/landing/laptop.png" class="lapscreen-image-geojit-landing " alt="" />
                            <img src="assets/landing/banner-left.svg" class="left-image-geojit-landing ">
                            <img src="assets/landing/banner-bottom.svg" class="bottom-image-geojit-landing ">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="banner-bottom ">
        <div class="d-flex">
            <div class="container py-md-4  ">
                <div class="cards card-geojit-landing">
                    <igx-card-header class="card-header-geojit-landing">
                        <igx-card-media>
                            <img class="card-img-left card-img-geojit-landing" src="assets/landing/secure.svg" />
                        </igx-card-media>
                        <div class="card-body card-title-geojit-loan">
                            <h4 class="card-title h5 h4-sm">Secure Storage</h4>
                            <p class="card-text">There are many variations of <br>injected humour, or randomised<br>
                                words which look believable.</p>
                        </div>
                    </igx-card-header>
                    <igx-card-header class="card-header-geojit-landing">
                        <igx-card-media>
                            <img class="card-img-left card-img-geojit-landing" src="assets/landing/insure.svg" />
                        </igx-card-media>
                        <div class="card-body card-title-geojit-loan">
                            <h4 class="card-title h5 h4-sm" style="margin-bottom: 8px;">Insurance Protection</h4>
                            <p class="card-text">There are many variations of <br>injected humour, or randomised<br>
                                words which look believable.</p>
                        </div>
                    </igx-card-header>
                    <igx-card-header class="card-header-geojit-landing" style="margin-right: 0px;">
                        <igx-card-media>
                            <img class="card-img-left card-img-geojit-landing" src="assets/landing/industry.svg" />
                        </igx-card-media>
                        <div class="card-body card-title-geojit-loan">
                            <h4 class="card-title h5 h4-sm" style="margin-bottom: 8px;">Industry Leading</h4>
                            <p class="card-text">There are many variations of <br>injected humour, or randomised<br>
                                words which look believable.</p>
                        </div>
                    </igx-card-header>
                </div>
            </div>
        </div>
    </section>
</div>

<div class="why-quod-geojit-landing">
    <div class="container">
        <div class="row">
            <div class="col-sm-5 whyquod-left-geojit-landing">
                <img src="assets/landing/why-quod.png" class="img-fluid image-why-geojit-landing">
            </div>
            <div class="col-sm-7 why-quod-geojit-landing">
                <a>About Us</a>
                <h6>Why Quod Finance?</h6>
                <div class="cards card-why-geojit-landing" style="  padding-top: 45px;">
                    <igx-card-header class=" col-sm-6">
                        <igx-card-media>
                            <img class="card-img-left " src="assets/landing/innovative.svg" />
                        </igx-card-media>
                        <div class="card-body card-title-geojit-loan ">
                            <h4 class="card-title h5 h4-sm">Innovative technology</h4>
                            <p class="card-text">There are many variations of passages of<br> Lorem Ipsum available, but
                                the majority have<br> suffered alteration in some form.</p>
                        </div>
                    </igx-card-header>
                    <igx-card-header class=" col-sm-6">
                        <igx-card-media>
                            <img class="card-img-left " src="assets/landing/trusted.svg" />
                        </igx-card-media>
                        <div class="card-body card-title-geojit-loan ">
                            <h4 class="card-title h5 h4-sm">Trusted for 25+ years</h4>
                            <p class="card-text">There are many variations of passages of<br> Lorem Ipsum available, but
                                the majority have<br> suffered alteration in some form.</p>
                        </div>
                    </igx-card-header>

                </div>
                <div class="cards card-why-geojit-landing">
                    <igx-card-header class=" col-sm-6">
                        <igx-card-media>
                            <img class="card-img-left" src="assets/landing/local.svg" />
                        </igx-card-media>
                        <div class="card-body card-title-geojit-loan ">
                            <h4 class="card-title h5 h4-sm">Local presence</h4>
                            <p class="card-text">There are many variations of passages of<br> Lorem Ipsum available, but
                                the majority have<br> suffered alteration in some form.</p>
                        </div>
                    </igx-card-header>
                    <igx-card-header class=" col-sm-6">
                        <igx-card-media>
                            <img class="card-img-left" src="assets/landing/join.svg"
                                style="width: 30px;height: 33px;" />
                        </igx-card-media>
                        <div class="card-body card-title-geojit-loan ">
                            <h4 class="card-title h5 h4-sm">Join 600,000 clients</h4>
                            <p class="card-text">There are many variations of passages of<br> Lorem Ipsum available, but
                                the majority have<br> suffered alteration in some form.</p>
                        </div>
                    </igx-card-header>
                </div>
            </div>
        </div>
    </div>
</div>
<div [class]="theme" style=" height: auto;">
    <div class=" container">
        <div class=" darklight-section-geojit-landing">
            <div class=" theme-chooser" style="    display: block;">

                <h4 igx-typography_h4 class="title-innovation-geojit-landing">The innovation platform will allow you
                    to<br> enjoy the convenience of trading</h4>
                <h6 igx-typography_h4>Monitor the markets on the go with out Quod finance application</h6>
                <div *ngIf="light" style="padding-top: 65px;">
                    <button (click)="setTheme('light')" class="light-button-geojit-landing"><img
                            src="assets/landing/sun.svg" style="padding: 0px 16px 1px 1px;">Light Mode</button>
                    <button (click)="setTheme('dark')" class="dark-button-geojit-landing"
                        style="color: #FFFFFF;box-shadow: 0px 3px 6px #D5DBE2;"><img src="assets/landing/moon-light.svg"
                            style=" padding: 0px 16px 1px 1px;">Dark Mode</button>
                </div>
                <div *ngIf="dark" style="padding-top: 65px;">
                    <button (click)="setTheme('light')" class="dark-button-geojit-landing"
                        style="box-shadow: 0px 3px 6px #11161C;  border-radius: 9px 0px 0px 9px;            "><img
                            style="padding: 0px 16px 1px 1px;" src="assets/landing/sun-light.svg">Light Mode</button>
                    <button (click)="setTheme('dark')" class="light-button-geojit-landing"
                        style="box-shadow: 0px 3px 6px #11161C;color: #000000;  border-radius: 0px 9px 9px 0px;  "><img
                            style="padding: 0px 16px 1px 1px;" src="assets/landing/moon.svg">Dark Mode</button>
                </div>
            </div>
            <div *ngIf="light" style="padding-top: 50px">
                <img src="assets/landing/banner-light.png" class="image-light-geojit-landing">
            </div>
            <div *ngIf="dark" style="padding-top: 50px">
                <img src="assets/landing/banner-dark.png" class="image-light-geojit-landing">
            </div>
            <div class="cards card-why-geojit-landing" style="justify-content: center;  padding-top: 45px;">
                <igx-card class="col-sm-4 card-platform-geojit-landing" style="box-shadow: none;">
                    <igx-card-media>
                        <img src="assets/landing/platform6.svg" style="    width: 50px;
                    height: 50px;">
                    </igx-card-media>
                    <igx-card-header>
                        <h3 class="cardtitle-platform-geojit-landing" igxCardHeaderTitle>Platform Features</h3>
                        <h5 class="cardsubtitle-platform-geojit-landing" igxCardHeaderSubtitle>There are many variations
                            of passages of Lorem<br> Ipsum available, but the majority have suffered<br> alteration in
                            some form. There are many <br>variations of passages of Lorem Ipsum<br> available, but the
                            majority have suffered <br>alteration in some form.</h5>
                    </igx-card-header>
                </igx-card>
                <igx-card class=" col-sm-4 card-platform-geojit-landing" style="box-shadow: none;">
                    <igx-card-media>
                        <img src="assets/landing/platform2.svg" style="    width: 50px;
                    height: 50px;">
                    </igx-card-media>
                    <igx-card-header>
                        <h3 class="cardtitle-platform-geojit-landing" igxCardHeaderTitle>Platform Features</h3>
                        <h5 class="cardsubtitle-platform-geojit-landing" igxCardHeaderSubtitle>There are many variations
                            of passages of Lorem<br> Ipsum available, but the majority have suffered<br> alteration in
                            some form. There are many <br>variations of passages of Lorem Ipsum<br> available, but the
                            majority have suffered <br>alteration in some form.</h5>
                    </igx-card-header>
                </igx-card>
                <igx-card class=" col-sm-4 card-platform-geojit-landing"
                    style="box-shadow: none;     margin-right: 0px;">
                    <igx-card-media>
                        <img src="assets/landing/platform3.svg" style="    width: 50px;
                    height: 50px;">
                    </igx-card-media>
                    <igx-card-header>
                        <h3 class="cardtitle-platform-geojit-landing" igxCardHeaderTitle>Platform Features</h3>
                        <h5 class="cardsubtitle-platform-geojit-landing" igxCardHeaderSubtitle>There are many variations
                            of passages of Lorem<br> Ipsum available, but the majority have suffered<br> alteration in
                            some form. There are many <br>variations of passages of Lorem Ipsum<br> available, but the
                            majority have suffered <br>alteration in some form.</h5>
                    </igx-card-header>
                </igx-card>
            </div>
            <div class="cards card-why-geojit-landing"
                style="justify-content: center;padding-top: 25px;padding-bottom: 66px;">
                <igx-card class=" col-sm-4 card-platform-geojit-landing" style="box-shadow: none;">
                    <igx-card-media>
                        <img src="assets/landing/platform4.svg" style="    width: 50px;
                height: 50px;">
                    </igx-card-media>
                    <igx-card-header>
                        <h3 class="cardtitle-platform-geojit-landing" igxCardHeaderTitle>Platform Features</h3>
                        <h5 class="cardsubtitle-platform-geojit-landing" igxCardHeaderSubtitle>There are many variations
                            of passages of Lorem<br> Ipsum available, but the majority have suffered<br> alteration in
                            some form. There are many <br>variations of passages of Lorem Ipsum<br> available, but the
                            majority have suffered <br>alteration in some form.</h5>
                    </igx-card-header>
                </igx-card>
                <igx-card class=" col-sm-4 card-platform-geojit-landing" style="box-shadow: none;">
                    <igx-card-media>
                        <img src="assets/landing/platform5.svg" style="    width: 50px;
                height: 50px;">
                    </igx-card-media>
                    <igx-card-header>
                        <h3 class="cardtitle-platform-geojit-landing" igxCardHeaderTitle>Platform Features</h3>
                        <h5 class="cardsubtitle-platform-geojit-landing" igxCardHeaderSubtitle>There are many variations
                            of passages of Lorem<br> Ipsum available, but the majority have suffered<br> alteration in
                            some form. There are many <br>variations of passages of Lorem Ipsum<br> available, but the
                            majority have suffered <br>alteration in some form.</h5>
                    </igx-card-header>
                </igx-card>
                <igx-card class=" col-sm-4 card-platform-geojit-landing"
                    style="box-shadow: none;    margin-right: 0px;">
                    <igx-card-media>
                        <img src="assets/landing/platform6.svg" style="    width: 50px;
                height: 50px;">
                    </igx-card-media>
                    <igx-card-header>
                        <h3 class="cardtitle-platform-geojit-landing" igxCardHeaderTitle>Platform Features</h3>
                        <h5 class="cardsubtitle-platform-geojit-landing" igxCardHeaderSubtitle>There are many variations
                            of passages of Lorem<br> Ipsum available, but the majority have suffered<br> alteration in
                            some form. There are many <br>variations of passages of Lorem Ipsum<br> available, but the
                            majority have suffered <br>alteration in some form.</h5>
                    </igx-card-header>
                </igx-card>
            </div>
            <div style="padding-bottom: 80px;">
                <button [ngStyle]="{'border-color':light ? '#000000' : '#FFFFFF'}"
                    class="button-loadmore-geojit-landing">Load More Features</button>
            </div>
        </div>
    </div>
</div>
<div>
    <div class="container">
        <div class="blue-started-geojit-landing">
            <div class=" py-md-4  ">
                <div class="row text-inside-geojit-landing">
                    <div class="col-md-6 ab-contents head-decentralised-geojit-landing">
                        <div class="col">
                            <h4 igx-typography__h4>Decentralised Edge <br>For Serious Traders</h4>
                        </div>
                        <div class="col">
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing diam <br>
                                nonumy eirmod diam nonumy eirmod tempor empor <br>
                                inviduntThere are many variations Lorem Ipsum are many<br>
                                majority have suffered in some form.</p>
                        </div>
                        <div class="col">
                            <button igxButton="raised" igxRipple>Get Started</button>
                        </div>
                    </div>
                    <div class="col-md-1 content-gd-wthree">
                    </div>
                    <div class="col-md-5 content-gd-wthree d-flex align-items-center" style="justify-content: center;">
                        <img src="assets/landing/decentralised.svg"
                            class="img-fluid z-idex- decentralised-image-geojit-landing" alt="" />
                    </div>
                </div>
            </div>
            <div>
                <div class="cards" style="padding: 50px 90px 0px 90px; ">
                    <igx-card-header class="col-sm-5 row">
                        <igx-card-media style="width: 27px;  border-top: 4px solid #17C9FF;
                         ">
                        </igx-card-media>
                        <div class="card-body ">
                            <h4 class="card-title h5 h4-sm" style="font-size: 35px;line-height: 20px;">9000+</h4>
                            <p class="card-text">Transactions Per Second</p>
                        </div>
                    </igx-card-header>
                    <igx-card-header class="col-sm-5 row">
                        <igx-card-media style="width: 27px;border-top: 4px solid #F68722;">
                        </igx-card-media>
                        <div class="card-body ">
                            <h4 class="card-title h5 h4-sm" style="font-size: 35px;line-height: 20px;">120+</h4>
                            <p class="card-text">Liquid Markets</p>
                        </div>
                    </igx-card-header>
                </div>
                <igx-card-header class="col-sm-2 row card-fees-geojit-landing">
                    <igx-card-media style="width: 27px;border-top: 4px solid #19C17D;">
                    </igx-card-media>
                    <div class="card-body ">
                        <h4 class="card-title h5 h4-sm" style="font-size: 35px;line-height: 20px;">0 - 0.2%</h4>
                        <p class="card-text">Fees</p>
                    </div>
                </igx-card-header>
            </div>
        </div>
    </div>
</div>
<div class="container how-itworks-geojit-landing">
    <div class="row">
        <div class="col-sm">
            <h6 style="font-size: 13px;">3 EASY STEPS</h6>
            <h6>How it Works?</h6>
        </div>
    </div>
    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 " style="text-align: center;">
        <div class="col-sm-4 mb-4">
            <igx-card-header class="col row">
                <igx-card-media>
                    <img class="card-img-left" src="assets/landing/create.svg" style="width: 171px;height: 193px;" />
                </igx-card-media>
                <div class="card-body ">
                    <h4 class="card-title h5 h4-sm">Create a new account</h4>
                    <p class="card-text">Lorem ipsum dolor sit amet, consetetur<br> tempor empor invidunt there are
                        many<br> variation.</p>
                </div>
            </igx-card-header>
        </div>
        <div class="col-sm-4 mb-4">
            <igx-card-header class="col row">
                <igx-card-media>
                    <img class="card-img-left" src="assets/landing/buy.svg" style="width: 208px;height: 206px;" />
                </igx-card-media>
                <div class="card-body ">
                    <h4 class="card-title h5 h4-sm">Buy/ Sell shares</h4>
                    <p class="card-text">Lorem ipsum dolor sit amet, consetetur<br> tempor empor invidunt there are
                        many<br> variation.</p>
                </div>
            </igx-card-header>
        </div>
        <div class="col-sm-4 mb-4">
            <igx-card-header class="col row">
                <igx-card-media>
                    <img class="card-img-left" src="assets/landing/ern.svg" style="width: 287px;height: 209px;" />
                </igx-card-media>
                <div class="card-body ">
                    <h4 class="card-title h5 h4-sm">Earn income</h4>
                    <p class="card-text">Lorem ipsum dolor sit amet, consetetur <br>tempor empor invidunt there are
                        many<br> variation.</p>
                </div>
            </igx-card-header>
        </div>
    </div>
</div>
<div class="getin-touch-geojit-landing">
    <div class="container">
        <div class="row ">
            <div class="col-sm-6 lets-connect-geojit-landing">
                <img src="assets/landing/lets-connect.svg" class="connect-image-geojit-landing">
                <h4 class="card-title h5 h4-sm">Get in touch, or<br> read more about us</h4>
                <a class="mt-4" href="">Read more about us</a>
            </div>
            <div class="col-sm-6 connect-right-geojit-landing">
                <h6>Let’s connect with us</h6>
                <a>For all enquiries, please email us using the form below.</a>
                <form action="/action_page.php" style="padding-top: 30px;">
                    <input class="input-box-geojit-landing" placeholder="Full Name*">
                    <input class="input-box-geojit-landing" placeholder="Phone number" style="max-width: 264px;">
                    <input class="input-box-geojit-landing input-align-geojit-landing" placeholder="Email Address"
                        style="max-width: 264px;">
                    <textarea id="w3review" name="w3review" class="input-box-geojit-landing" rows="4" cols="50"
                        placeholder="Your message" style="height: 86px;padding: 9px 0px 0px 15px;"></textarea>
                    <label class="checkbox-geojit-landing">
                        <input type="checkbox">
                        <span class="checkmark"></span>
                        <span style="padding-left: 36px;">I agree to<a> Terms</a> and<a> Privacy policy</a></span>
                    </label>
                    <input class="btn-sent-geojit-landing" type="submit" value="Sent a message">
                </form>
            </div>
        </div>
    </div>
</div>
<div style="background: #0B0B0B 0% 0% no-repeat padding-box; ">
    <div class="container footer-geojit-landing">
        <img src="assets/landing/logo.svg">
        <select class="selectpicker language-select-geojit-landing" data-width="fit">
            <option data-content='<span class="flag-icon flag-icon-us"></span> English'>English</option>
            <option data-content='<span class="flag-icon flag-icon-mx"></span> Español'>Español</option>
        </select>
        <a class="social-sharing-geojit-landing">
            <span>Facebook .</span>
            <span>Twitter .</span>
            <span>Instagram</span>
        </a>
        <div class="row footer-nav-geojit-landing">
            <div class="col-lg-2  mt-md-0 mt-4 pull-left">
                <ul style="padding: 0px 0px;" class="footer-mobile-geojit-landing">
                    <li>
                        <a style="font-weight:600 ;cursor: text;color: #586386;"> Company</a>
                    </li>
                    <li>
                        <a>About</a>
                    </li>
                    <li>
                        <a>Products</a>
                    </li>
                    <li>
                        <a>Downloads</a>
                    </li>
                    <li>
                        <a>Partners</a>
                    </li>
                    <li>
                        <a>News & Press</a>
                    </li>
                    <li>
                        <a>Quod Insight</a>
                    </li>
                    <li>
                        <a>Legal & privacy</a>
                    </li>
                    <li>
                        <a>Cookie policy</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-4 footer_wthree_gridf mt-md-0 mt-sm-4 " style="max-width: 350px;">
                <ul class="footer_wthree_gridf_list footer-mobile-geojit-landing" style="padding: 0px 0px;">
                    <li>
                        <a style="font-weight:600; cursor: text;color: #586386;">Quod Products</a>
                    </li>
                    <li>
                        <a>Algorithmic Trading</a>
                    </li>
                    <li>
                        <a>Leading Automated Trading Platform</a>
                    </li>
                    <li>
                        <a>Connectivity</a>
                    </li>
                    <li>
                        <a>Execution Management Systems (EMS)</a>
                    </li>
                    <li>
                        <a>Adaptive Market Making (MM) Technology</a>
                    </li>
                    <li>
                        <a>Order Management System (OMS)</a>
                    </li>
                    <li>
                        <a>Quod Smart Order Routing (SOR)</a>
                    </li>
                    <li>
                        <a>TCA and Best Execution Reporting</a>
                    </li>


                </ul>
            </div>
            <div class="col-lg-2 address-text-geojit-landing mt-md-0 mt-sm-4">
                <ul class="footer-mobile-geojit-landing">
                    <li>
                        <a style="font-weight:600; cursor: text;color: #586386;font-size: 16px;"> Office </a>
                    </li>
                    <li>
                        <a style="font-size: 16px;padding: 7px 0px 1px 0px;font-weight: bold;">London</a>
                    </li>
                    <li>
                        <a>45 Moorfields, Moorgate, London<br> EC2Y 9AE, United Kingdom <br>T: +44 20 7997 7020</a>
                    </li>
                    <li style="padding: 0px 0px 0px 0px;font-size: 0px;">
                        <a>London</a>
                    </li>
                    <li>
                        <a style="font-size: 16px;font-weight: bold;padding: 7px 0px 1px 0px;">Hong Kong</a>
                    </li>
                    <li>
                        <a>Unit 2008, 20/F, Tower 1, Lippo<br>Centre, 89 Queensway, Hong Kong<br> T: +852 3955 8890<br>
                            T: +86 108 639 1443</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 address-text-geojit-landing mt-md-0 mt-sm-4 footer-address-geojit-landing ">
                <ul class="address-text-geojit-landing footer-mobile-geojit-landing">
                    <li style="font-size: 0px;">
                        <a> Office </a>
                    </li>
                    <li>
                        <a style="font-size: 16px;font-weight: bold;">New York</a>
                    </li>
                    <li>
                        <a>675 3rd Avenue, Suite 3004<br> New York, NY 10017, USA<br> T: +1 92 92 92 8090</a>
                    </li>
                    <li style="padding: 0px 0px 0px 0px;font-size: 0px;">
                        <a>London</a>
                    </li>
                    <li>
                        <a style="font-size: 16px;font-weight: bold;padding: 7px 0px 1px 0px;">Lille</a>
                    </li>
                    <li>
                        <a>165 Avenue de Bretagne<br> 59000, Lille, France</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 address-text-geojit-landing mt-md-0 mt-sm-4 ">
                <ul class="address-text-geojit-landing footer-mobile-geojit-landing">
                    <li style="font-size: 0px;">
                        <a> Office </a>
                    </li>
                    <li>
                        <a style="font-size: 16px;font-weight: bold;">Paris</a>
                    </li>
                    <li>
                        <a>6 Boulevard Sebastopol,<br> 75004, Paris, France<br> T: +33 974 59 4445</a>
                    </li>
                    <li style="padding: 0px 0px 0px 0px;font-size: 0px;">
                        <a>London</a>
                    </li>
                    <li>
                        <a style="font-size: 16px;font-weight: bold;padding: 7px 0px 1px 0px;">Dubai</a>
                    </li>
                    <li>
                        <a>Building 16, Dubai Internet City,<br> Dubai, UAE<br> T: +971 8000 320 113</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="cpy-right-geojit-landing  py-3 ">
        <div class="container">
            <div class="row" style="border-top: 2px solid #404450;
            opacity: 1;margin-top: 50px;">
                <div class="col-lg-8 terms-cond-geojit-landing"> ©2021 Quod Financial - All Rights Reserved. <br> <a
                        class="terms-cond-geojit-landing"> Registered Office: 55 Baker Street, London, W1U 7EU </a> <a
                        class="hidden-mobile-geojit-landing ml-3 mr-3"> | </a><a
                        class="terms-cond-geojit-landing">Company Number: 05957359 </a><a
                        class="space-mobile-geojit-landing">|</a> <a class="terms-cond-geojit-landing">VAT Number:
                        GB919817485y</a></div>
                <div class="col-lg-4 cpy-text-geojit-landing"> <a> Designed by : webandcrafts.com</a></div>
            </div>
        </div>
    </div>
</div>