import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-ticket',
  templateUrl: './order-ticket.component.html',
  styleUrls: ['./order-ticket.component.scss']
})
export class OrderTicketComponent implements OnInit {

  currentBuyValue: number = 0;
  public density = 'comfortable';
  limitSelect: string = 'Limit';
  public date = new Date();
  statusClass = 'active';
  sellActive = 'not-active-sell'
  bgActive = 'active-bg'
  setActiveClass() {
    this.sellActive = 'not-active-sell';
    this.statusClass = 'active';
    this.bgActive = 'active-bg';
  }
  sell() {
    this.sellActive = 'active-sell';
    this.statusClass = 'not-active';
    this.bgActive = 'not-active-bg';

  }

  constructor() {
    this.currentBuyValue = 50;
    this.limitSelect = "Limit";
  }

  ngOnInit(): void {


  }

}
