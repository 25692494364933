import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IgxCheckboxModule, IgxProgressBarModule } from "@infragistics/igniteui-angular";
import { IgxAvatarModule, IgxGridModule, IgxIconModule, IgxNavbarModule } from "@infragistics/igniteui-angular";
import { IgxCardModule } from '@infragistics/igniteui-angular';
import { GeneralDashboardComponent } from './general-dashboard/general-dashboard.component';
import { FormsModule } from '@angular/forms';
import { IgxInputGroupModule, IgxRadioModule, IgxSelectModule, IgxTabsModule, IgxDatePickerModule } from '@infragistics/igniteui-angular';
import { HomePageComponent } from './home-page/home-page.component';
import { HeaderComponent } from './shared/header/header.component';
import { GeneralDashboardModule } from './general-dashboard/general-dashboard.module';
import { WindowTabsComponent } from './general-dashboard/window-tabs/window-tabs.component';
import { WatchlistTabsComponent } from './general-dashboard/watchlist-tabs/watchlist-tabs.component';
import { StockSummaryComponent } from './general-dashboard/stock-summary/stock-summary.component';
import { DashboardHeaderComponent } from './shared/dashboard-header/dashboard-header.component';
import { BuyingLayoutComponent } from './general-dashboard/buying-layout/buying-layout.component';
import { OrderTicketComponent } from './general-dashboard/order-ticket/order-ticket.component';
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { defineCustomElements } from '@infragistics/igniteui-dockmanager/loader';
defineCustomElements();
import {
  IgxCategoryChartModule,
  IgxItemLegendModule,
  IgxLegendModule,
  IgxPieChartModule
} from "igniteui-angular-charts";
import { TechResearchTabsComponent } from './general-dashboard/tech-research-tabs/tech-research-tabs.component';
import { MyordersTabsComponent } from './general-dashboard/myorders-tabs/myorders-tabs.component';
import { DashboardFooterComponent } from './shared/dashboard-footer/dashboard-footer.component';
import { DynamicGridComponent } from './general-dashboard/dynamic-grid/dynamic-grid.component';
@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    GeneralDashboardComponent,
    HomePageComponent,
    HeaderComponent,
    WindowTabsComponent,
    WatchlistTabsComponent,
    StockSummaryComponent,
    DashboardHeaderComponent,
    BuyingLayoutComponent,
    TechResearchTabsComponent,
    MyordersTabsComponent,
    DashboardFooterComponent,
    OrderTicketComponent,
    DynamicGridComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    IgxIconModule,
    IgxNavbarModule,
    IgxCardModule,
    IgxInputGroupModule,
    IgxCheckboxModule,
    FormsModule,
    IgxSelectModule,
    IgxInputGroupModule,
    IgxRadioModule,
    IgxTabsModule,
    IgxAvatarModule,
    IgxGridModule,
    IgxCategoryChartModule,
    IgxLegendModule,
    IgxProgressBarModule,
    IgxDatePickerModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
