import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GeneralDashboardComponent } from './general-dashboard/general-dashboard.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { SigninComponent } from './user/signin/signin.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent
  },
  {
    path: 'dashboard',
    component: GeneralDashboardComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./general-dashboard/general-dashboard.module').then(m => m.GeneralDashboardModule)
      }]
  },
  {
    path: 'home',
    component: HomePageComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule)
      }]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
