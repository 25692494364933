import { Component, OnInit } from '@angular/core';
import { DataServiceService } from '../../services/data-service.service';
@Component({
  selector: 'app-buying-layout',
  templateUrl: './buying-layout.component.html',
  styleUrls: ['./buying-layout.component.scss'],

})
export class BuyingLayoutComponent implements OnInit {

  public density = 'comfortable';
  inputValue='4250 (D)';
  symbolSearch='';
  orderValue1="30,00,000.00";
  orderValue2="-";
  orderValue3="-";
  orderValue4="30,00,000.00";
  orderValue5="-";
  orderValue6="-";

  constructor(public DataServiceService:DataServiceService) { }

  ngOnInit(): void {
  }
 
}
