import { Component, ElementRef, ViewChild, OnInit } from "@angular/core";
import {IgcContentPane,IgcDockManagerComponent,IgcDockManagerLayout,IgcDockManagerPaneType,IgcSplitPane,IgcSplitPaneOrientation, IgcDocumentHost, IgcTabGroupPane} from '@infragistics/igniteui-dockmanager';
import { DataServiceService } from '../../services/data-service.service';

@Component({
    selector: 'app-window-tabs',
    templateUrl: './window-tabs.component.html',
    styleUrls: ['./window-tabs.component.scss']
})
export class WindowTabsComponent implements OnInit {

    @ViewChild("dockManager1")
    dockManager1!: ElementRef<IgcDockManagerComponent>;
    @ViewChild("dockManager2")
    dockManager2!: ElementRef<IgcDockManagerComponent>;
    @ViewChild("dockManager3")
    dockManager3!: ElementRef<IgcDockManagerComponent>;
    @ViewChild("dockManager4")
    dockManager4!: ElementRef<IgcDockManagerComponent>;
    @ViewChild("dockManager5")
    dockManager5!: ElementRef<IgcDockManagerComponent>;
    @ViewChild("dockManager6")
    dockManager6!: ElementRef<IgcDockManagerComponent>;
    @ViewChild("dockManager7")
    dockManager7!: ElementRef<IgcDockManagerComponent>;
    @ViewChild("dockManager8")
    dockManager8!: ElementRef<IgcDockManagerComponent>;
    @ViewChild("dockManager9")
    dockManager9!: ElementRef<IgcDockManagerComponent>;

    @ViewChild("dockManagerw")
    dockManagerw!: ElementRef<IgcDockManagerComponent>;
    passid: string[] = [];
    orderid: string[] = [];
    techid: string[] = [];
    stockid: string[] = [];
    buyid: string[] = [];
    layoutid: string[] = [];
    ticketid: string[] = [];
    workskpace: string[] = [];
    workskpacePanes: any[] = [];
    layoutsArray: any[] = [];
    newPaneid: any;
    constructor(public callserive: DataServiceService) {
    }
    ngOnInit(): void {
        this.callserive.customObservable.subscribe((res) => {
            if (res == "startWatch") { this.addPane("My Watchlist"); }
            if (res == "startStock") { this.addPane("Stock Summary"); }
            if (res == "startOrder") { this.addPane("Order Window"); }
            if (res == "startTech") { this.addPane("Tech Research"); }
            if (res == "startBuy") { this.addPane("Buying Pane"); }
            if (res == "startWorkspace") { this.startWindow(); }
            if (res == "orderTicket") { this.addPane("Order Ticket"); }
        }
        );
        let inputValue = document.getElementById("dockManagerw");
        inputValue!.addEventListener('activePaneChanged', ev => {
            this.newPaneid = (ev as any).detail.newPane.contentId;

        });
    }

    public windowLayout: IgcDockManagerLayout = {
        rootPane: {
            type: IgcDockManagerPaneType.splitPane,
            orientation: IgcSplitPaneOrientation.vertical,
            size: 200,
            panes: [
                {
                    type: IgcDockManagerPaneType.documentHost,
                    size: 200,
                    rootPane: {
                        type: IgcDockManagerPaneType.splitPane,
                        orientation: IgcSplitPaneOrientation.horizontal,
                        panes: [
                            {
                                type: IgcDockManagerPaneType.tabGroupPane,
                                panes: [
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: 'Window 01',
                                        contentId: 'content1',
                                        allowMaximize: false

                                    }
                                ]
                            }
                        ]
                    },
                }
            ]
        }
    }

    public layoutscreen1: IgcDockManagerLayout = {
        rootPane: {
            type: IgcDockManagerPaneType.splitPane,
            orientation: IgcSplitPaneOrientation.horizontal,

            panes: [
                {
                    type: IgcDockManagerPaneType.documentHost,
                    size: 200,
                    rootPane: {

                        type: IgcDockManagerPaneType.splitPane,
                        orientation: IgcSplitPaneOrientation.vertical,
                        size: 300,

                        panes: [
                            {
                                type: IgcDockManagerPaneType.tabGroupPane,

                                panes: [
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: 'My Watchlist',
                                        contentId: 'content1'
                                    },
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: 'My Watchlist 01',
                                        contentId: 'content4'
                                    },
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: 'My Watchlist 02',
                                        contentId: 'content4'
                                    },
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: 'My Watchlist 03',
                                        contentId: 'content4'
                                    },
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: 'My Watchlist 04',
                                        contentId: 'content4'
                                    }
                                ]
                            },

                            {
                                type: IgcDockManagerPaneType.splitPane,
                                orientation: IgcSplitPaneOrientation.horizontal,

                                panes: [
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        contentId: 'contentbuying',
                                        header: '',
                                        isPinned: true,
                                        allowClose: true,
                                        allowMaximize: false

                                    },
                                    {
                                        type: IgcDockManagerPaneType.tabGroupPane,
                                        panes: [
                                            {
                                                type: IgcDockManagerPaneType.contentPane,
                                                header: 'My Orders',
                                                contentId: 'contentorder',
                                                allowClose: true
                                            },
                                            {
                                                type: IgcDockManagerPaneType.contentPane,
                                                header: 'Holding',
                                                contentId: 'content4'
                                            },
                                            {
                                                type: IgcDockManagerPaneType.contentPane,
                                                header: 'Positions',
                                                contentId: 'content4'
                                            },
                                            {
                                                type: IgcDockManagerPaneType.contentPane,
                                                header: 'Broker Rejections',
                                                contentId: 'content4'
                                            }

                                        ]
                                    }

                                ]

                            },

                        ]
                    }
                },
                {
                    type: IgcDockManagerPaneType.splitPane,
                    orientation: IgcSplitPaneOrientation.vertical,
                    size: 200,
                    panes: [
                        {
                            type: IgcDockManagerPaneType.documentHost,
                            size: 200,
                            rootPane: {
                                type: IgcDockManagerPaneType.splitPane,
                                orientation: IgcSplitPaneOrientation.vertical,
                                panes: [
                                    {
                                        type: IgcDockManagerPaneType.tabGroupPane,
                                        panes: [

                                        ]
                                    },

                                ]

                            },

                        }
                    ]

                },
                {
                    type: IgcDockManagerPaneType.splitPane,
                    orientation: IgcSplitPaneOrientation.vertical,

                    panes: [
                        {
                            type: IgcDockManagerPaneType.documentHost,
                            size: 200,
                            rootPane: {
                                type: IgcDockManagerPaneType.splitPane,
                                orientation: IgcSplitPaneOrientation.vertical,

                                panes: [
                                    {

                                        type: IgcDockManagerPaneType.tabGroupPane,
                                        size: 200,

                                        panes: [
                                            {
                                                type: IgcDockManagerPaneType.contentPane,
                                                contentId: 'content6',
                                                header: 'Stock Summary'
                                            },
                                            {
                                                type: IgcDockManagerPaneType.contentPane,
                                                contentId: 'Portfolio',
                                                header: 'Portfolio'
                                            },
                                            {
                                                type: IgcDockManagerPaneType.contentPane,
                                                contentId: 'content8',
                                                header: 'Offline Order'
                                            },
                                            {
                                                type: IgcDockManagerPaneType.contentPane,
                                                contentId: 'content9',
                                                header: 'Day’s History'
                                            }


                                        ]
                                    },
                                    {
                                        type: IgcDockManagerPaneType.tabGroupPane,
                                        size: 305,

                                        panes: [
                                            {
                                                type: IgcDockManagerPaneType.contentPane,
                                                contentId: 'content11',
                                                header: 'Tech Research'
                                            },
                                            {
                                                type: IgcDockManagerPaneType.contentPane,
                                                contentId: 'Portfolio',
                                                header: 'Market News'
                                            },
                                            {
                                                type: IgcDockManagerPaneType.contentPane,
                                                contentId: 'content8',
                                                header: 'Mutual Funds'
                                            },
                                            {
                                                type: IgcDockManagerPaneType.contentPane,
                                                contentId: 'content9',
                                                header: 'Fundamental'
                                            }


                                        ]
                                    }

                                ]
                            }
                        }
                    ]
                }
            ]
        },
        floatingPanes: [
            {
                type: IgcDockManagerPaneType.splitPane,
                orientation: IgcSplitPaneOrientation.horizontal,
                floatingHeight: 150,
                floatingWidth: 250,
                floatingLocation: { x: 300, y: 200 },
                floatingResizable: false,
                panes: [

                ]
            }
        ]
    };
    public stocklayout: IgcDockManagerLayout = {
        rootPane: {

            type: IgcDockManagerPaneType.splitPane,
            orientation: IgcSplitPaneOrientation.vertical,
            size: 200,
            panes: [
                {
                    type: IgcDockManagerPaneType.documentHost,
                    size: 200,
                    rootPane: {
                        type: IgcDockManagerPaneType.splitPane,
                        orientation: IgcSplitPaneOrientation.horizontal,
                        panes: [
                            {
                                type: IgcDockManagerPaneType.tabGroupPane,

                                panes: [
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: 'Time',
                                        contentId: 'time',
                                        allowClose: false,
                                        allowMaximize: false,
                                        size: 200,
                                        allowDocking: false,
                                        allowFloating: false

                                    },
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: '15m',
                                        contentId: 'ti',
                                        allowClose: false,
                                        allowMaximize: false,
                                        size: 200,
                                        allowDocking: false,

                                    },
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: '4H',
                                        contentId: 'time',
                                        allowClose: false,
                                        allowMaximize: false,
                                        allowDocking: false,

                                    },
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: '1D',
                                        contentId: 'ti',
                                        allowClose: false,
                                        allowMaximize: false,
                                        allowDocking: false,

                                    },
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: '1W',
                                        contentId: 'ti',
                                        allowClose: false,
                                        allowMaximize: false,
                                        allowDocking: false,

                                    }

                                ]
                            }
                        ]

                    },

                }
            ]

        }
    }

    public layoutscreen2: IgcDockManagerLayout = {

        rootPane: {
            type: IgcDockManagerPaneType.splitPane,
            orientation: IgcSplitPaneOrientation.horizontal,

            panes: [
                {
                    type: IgcDockManagerPaneType.documentHost,
                    size: 200,
                    rootPane: {

                        type: IgcDockManagerPaneType.splitPane,
                        orientation: IgcSplitPaneOrientation.vertical,
                        size: 200,

                        panes: [
                            {
                                type: IgcDockManagerPaneType.tabGroupPane,

                                panes: [
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: '',
                                        contentId: 'content2',
                                        hidden: true
                                    }

                                ]
                            },
                        ]
                    }
                },
            ]
        },

        floatingPanes: [
            {
                type: IgcDockManagerPaneType.splitPane,
                orientation: IgcSplitPaneOrientation.horizontal,
                floatingHeight: 440,
                floatingWidth: 700,
                floatingLocation: { x: 300, y: 200 },
                floatingResizable: false,
                panes: [
                ],
            },
        ],
    };

    public layoutscreen3: IgcDockManagerLayout = {

        rootPane: {
            type: IgcDockManagerPaneType.splitPane,
            orientation: IgcSplitPaneOrientation.horizontal,

            panes: [
                {
                    type: IgcDockManagerPaneType.documentHost,
                    size: 200,
                    rootPane: {

                        type: IgcDockManagerPaneType.splitPane,
                        orientation: IgcSplitPaneOrientation.vertical,
                        size: 200,

                        panes: [
                            {
                                type: IgcDockManagerPaneType.tabGroupPane,

                                panes: [
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: '',
                                        contentId: 'content3',
                                        hidden: true

                                    }

                                ]
                            },
                        ]
                    }
                },
            ]
        },

        floatingPanes: [
            {
                type: IgcDockManagerPaneType.splitPane,
                orientation: IgcSplitPaneOrientation.horizontal,
                floatingHeight: 440,
                floatingWidth: 700,
                floatingLocation: { x: 300, y: 200 },
                floatingResizable: false,
                panes: [
                ],
            },
        ],
    };

    public layoutscreen4: IgcDockManagerLayout = {

        rootPane: {
            type: IgcDockManagerPaneType.splitPane,
            orientation: IgcSplitPaneOrientation.horizontal,

            panes: [
                {
                    type: IgcDockManagerPaneType.documentHost,
                    size: 200,
                    rootPane: {

                        type: IgcDockManagerPaneType.splitPane,
                        orientation: IgcSplitPaneOrientation.vertical,
                        size: 200,

                        panes: [
                            {
                                type: IgcDockManagerPaneType.tabGroupPane,

                                panes: [
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: '',
                                        contentId: 'content4',
                                        hidden: true

                                    }

                                ]
                            },
                        ]
                    }
                },
            ]
        },

        floatingPanes: [
            {
                type: IgcDockManagerPaneType.splitPane,
                orientation: IgcSplitPaneOrientation.horizontal,
                floatingHeight: 440,
                floatingWidth: 700,
                floatingLocation: { x: 300, y: 200 },
                floatingResizable: false,
                panes: [
                ],
            },
        ],
    };


    public layoutscreen5: IgcDockManagerLayout = {

        rootPane: {
            type: IgcDockManagerPaneType.splitPane,
            orientation: IgcSplitPaneOrientation.horizontal,

            panes: [
                {
                    type: IgcDockManagerPaneType.documentHost,
                    size: 200,
                    rootPane: {

                        type: IgcDockManagerPaneType.splitPane,
                        orientation: IgcSplitPaneOrientation.vertical,
                        size: 200,

                        panes: [
                            {
                                type: IgcDockManagerPaneType.tabGroupPane,

                                panes: [
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: '',
                                        contentId: 'content5',
                                        hidden: true

                                    }

                                ]
                            },
                        ]
                    }
                },
            ]
        },

        floatingPanes: [
            {
                type: IgcDockManagerPaneType.splitPane,
                orientation: IgcSplitPaneOrientation.horizontal,
                floatingHeight: 440,
                floatingWidth: 700,
                floatingLocation: { x: 300, y: 200 },
                floatingResizable: false,
                panes: [
                ],
            },
        ],
    };


    public layoutscreen6: IgcDockManagerLayout = {

        rootPane: {
            type: IgcDockManagerPaneType.splitPane,
            orientation: IgcSplitPaneOrientation.horizontal,

            panes: [
                {
                    type: IgcDockManagerPaneType.documentHost,
                    size: 200,
                    rootPane: {

                        type: IgcDockManagerPaneType.splitPane,
                        orientation: IgcSplitPaneOrientation.vertical,
                        size: 200,

                        panes: [
                            {
                                type: IgcDockManagerPaneType.tabGroupPane,

                                panes: [
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: '',
                                        contentId: 'content6',
                                        hidden: true

                                    }

                                ]
                            },
                        ]
                    }
                },
            ]
        },

        floatingPanes: [
            {
                type: IgcDockManagerPaneType.splitPane,
                orientation: IgcSplitPaneOrientation.horizontal,
                floatingHeight: 440,
                floatingWidth: 700,
                floatingLocation: { x: 300, y: 200 },
                floatingResizable: false,
                panes: [
                ],
            },
        ],
    };


    public layoutscreen7: IgcDockManagerLayout = {

        rootPane: {
            type: IgcDockManagerPaneType.splitPane,
            orientation: IgcSplitPaneOrientation.horizontal,

            panes: [
                {
                    type: IgcDockManagerPaneType.documentHost,
                    size: 200,
                    rootPane: {

                        type: IgcDockManagerPaneType.splitPane,
                        orientation: IgcSplitPaneOrientation.vertical,
                        size: 200,

                        panes: [
                            {
                                type: IgcDockManagerPaneType.tabGroupPane,

                                panes: [
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: '',
                                        contentId: 'content7'
                                    }

                                ]
                            },
                        ]
                    }
                },
            ]
        },

        floatingPanes: [
            {
                type: IgcDockManagerPaneType.splitPane,
                orientation: IgcSplitPaneOrientation.horizontal,
                floatingHeight: 440,
                floatingWidth: 700,
                floatingLocation: { x: 300, y: 200 },
                floatingResizable: false,
                panes: [
                ],
            },
        ],
    };


    public layoutscreen8: IgcDockManagerLayout = {

        rootPane: {
            type: IgcDockManagerPaneType.splitPane,
            orientation: IgcSplitPaneOrientation.horizontal,

            panes: [
                {
                    type: IgcDockManagerPaneType.documentHost,
                    size: 200,
                    rootPane: {

                        type: IgcDockManagerPaneType.splitPane,
                        orientation: IgcSplitPaneOrientation.vertical,
                        size: 200,

                        panes: [
                            {
                                type: IgcDockManagerPaneType.tabGroupPane,

                                panes: [
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: '',
                                        contentId: 'content8'
                                    }

                                ]
                            },
                        ]
                    }
                },
            ]
        },

        floatingPanes: [
            {
                type: IgcDockManagerPaneType.splitPane,
                orientation: IgcSplitPaneOrientation.horizontal,
                floatingHeight: 440,
                floatingWidth: 700,
                floatingLocation: { x: 300, y: 200 },
                floatingResizable: false,
                panes: [
                ],
            },
        ],
    };

    public layoutscreen9: IgcDockManagerLayout = {

        rootPane: {
            type: IgcDockManagerPaneType.splitPane,
            orientation: IgcSplitPaneOrientation.horizontal,

            panes: [
                {
                    type: IgcDockManagerPaneType.documentHost,
                    size: 200,
                    rootPane: {

                        type: IgcDockManagerPaneType.splitPane,
                        orientation: IgcSplitPaneOrientation.vertical,
                        size: 200,

                        panes: [
                            {
                                type: IgcDockManagerPaneType.tabGroupPane,

                                panes: [
                                    {
                                        type: IgcDockManagerPaneType.contentPane,
                                        header: '',
                                        contentId: 'content9'
                                    }

                                ]
                            },
                        ]
                    }
                },
            ]
        },

        floatingPanes: [
            {
                type: IgcDockManagerPaneType.splitPane,
                orientation: IgcSplitPaneOrientation.horizontal,
                floatingHeight: 440,
                floatingWidth: 700,
                floatingLocation: { x: 300, y: 200 },
                floatingResizable: false,
                panes: [
                ],
            },
        ],
    };

    addPane(res: any) {
        var thisnew = this;
        let dockmanger = thisnew.newPaneid ? thisnew.newPaneid.replace('content', '') : 1;
        var layout: any;
        if (dockmanger == 1) {
            layout = this.dockManager1.nativeElement.layout;
        } else if (dockmanger == 2) {
            layout = this.dockManager2.nativeElement.layout;
        } else if (dockmanger == 3) {
            layout = this.dockManager3.nativeElement.layout;
        }
        if (dockmanger == 4) {
            layout = this.dockManager4.nativeElement.layout;
        } else if (dockmanger == 5) {
            layout = this.dockManager5.nativeElement.layout;
        } else if (dockmanger == 6) {
            layout = this.dockManager6.nativeElement.layout;
        }
        if (dockmanger == 7) {
            layout = this.dockManager7.nativeElement.layout;
        } else if (dockmanger == 8) {
            layout = this.dockManager8.nativeElement.layout;
        } else if (dockmanger == 9) {
            layout = this.dockManager9.nativeElement.layout;
        }
        let text = "";
        function makeRandom(lengthOfCode: number, possible: string) {
            for (let i = 0; i < lengthOfCode; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            if (res == "Buying Pane") { thisnew.buyid.push(text);}
            else if (res == "Tech Research") {thisnew.techid.push(text);}
            else if (res == "Stock Summary") {thisnew.stockid.push(text);}
            else if (res == "Order Window") {thisnew.orderid.push(text);}
            else if (res == "My Watchlist") { thisnew.passid.push(text);}
            
            const floatingPanes2: IgcSplitPane =
            {
                type: IgcDockManagerPaneType.splitPane,
                orientation: IgcSplitPaneOrientation.horizontal,
                floatingHeight: 550,
                floatingWidth: 550,
                floatingLocation: { x: 300, y: 200 },
                floatingResizable: true,

                panes: [
                    {
                        type: IgcDockManagerPaneType.contentPane,
                        contentId: text,
                        header: res,

                    }
                ]
            };
            if (res == "Order Ticket") {
                thisnew.ticketid.push(text);
                (layout.floatingPanes?.push({
                    type: IgcDockManagerPaneType.splitPane,
                    orientation: IgcSplitPaneOrientation.horizontal,
                    floatingHeight: 833,
                    floatingWidth: 566,
                    floatingLocation: { x: 462, y: 60 },
                    floatingResizable: false,
                    allowDocking: false,
                    panes: [

                    ],

                }));
            }
            else {
                (layout.floatingPanes?.push({
                    type: IgcDockManagerPaneType.splitPane,
                    orientation: IgcSplitPaneOrientation.horizontal,
                    floatingHeight: 440,
                    floatingWidth: 700,
                    floatingLocation: { x: 300, y: 200 },
                    floatingResizable: false,
                    panes: [
                    ],

                }));
            }

            (layout.floatingPanes![layout?.floatingPanes!.length - 1] as IgcSplitPane).panes.push(floatingPanes2);

            return text;
        }
        let possible = "ABCDEFgsgdfgGHIJKLfggMNOPQRSdsgTUVWXYZ";
        const lengthOfCode = 20;
        makeRandom(lengthOfCode, possible);
        let y = thisnew.newPaneid ? thisnew.newPaneid.replace('content', '') : 1;
        if (y == 1) {
            this.layoutscreen1 = { ...layout };
        } else if (y == 2) {
            this.layoutscreen2 = { ...layout };
        } else if (y == 3) {
            this.layoutscreen3 = { ...layout };
        }
        if (y == 4) {
            this.layoutscreen4 = { ...layout };
        } else if (y == 5) {
            this.layoutscreen5 = { ...layout };
        } else if (y == 6) {
            this.layoutscreen6 = { ...layout };
        }
        if (y == 7) {
            this.layoutscreen7 = { ...layout };
        } else if (y == 8) {
            this.layoutscreen8 = { ...layout };
        } else if (y == 9) {
            this.layoutscreen9 = { ...layout };
        }
    }
    startWindow() {
        const thisn = this;
        var workspace: any[] = [];
        workspace.push(this.dockManagerw.nativeElement.layout);
        this.workskpacePanes = workspace[0].rootPane.panes[0].rootPane.panes[0].panes;
        let text = "";
        function makeRandom(lengthOfCode: number, possible: string) {

            for (let i = 0; i < lengthOfCode; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            thisn.layoutid.push(text);
            let x = ((workspace[0].rootPane.panes[0] as IgcDocumentHost).rootPane.panes[0] as IgcTabGroupPane).panes.length + 1
            const floatingPanes2: IgcContentPane =
            {
                type: IgcDockManagerPaneType.contentPane,
                header: "Window 0" + (((workspace[0].rootPane.panes[0] as IgcDocumentHost).rootPane.panes[0] as IgcTabGroupPane).panes.length + 1),
                contentId: 'content' + x
            };

            ((workspace[0].rootPane.panes[0] as IgcDocumentHost).rootPane.panes[0] as IgcTabGroupPane).panes.push(floatingPanes2);
            return text;
        }
        let possible = "ABCDEFgsgdfgGHIJKLfggMNOPQRSdsgTUVWXYZ";
        const lengthOfCode = 20;
        makeRandom(lengthOfCode, possible);
        thisn.windowLayout = { ...workspace[0] };
    }
}





